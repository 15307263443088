import { Action, ActionType } from './local-actions';
import { HeadroomState } from './local-state';

export const headroomStateReducer = (state: HeadroomState, action: Action) => {
    switch (action.type) {
        case ActionType.HEADER_STATE_CHANGE: {
            const { headerPinned } = action.payload;

            return {
                ...state,
                headerPinned,
            };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
