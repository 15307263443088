import { createGenericContext } from './generic-context';

export interface CurrentLocationState {
    isSearchInHeader: boolean;
    isHeaderTransparent: boolean;
}

const GenericContext = createGenericContext<CurrentLocationState>({
    isSearchInHeader: false,
    isHeaderTransparent: true,
});

export const ScrollerProvider = GenericContext.Provider;

export const useScrollerContext = GenericContext.useGenericContext;
