import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';

export const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if (firebase.apps.length !== 2) {
    firebase.initializeApp(firebaseConfig);
    firebase.initializeApp(firebaseConfig, 'authSecondary');

    // firebase.firestore();
    if (typeof window !== 'undefined' && 'measurementId' in firebaseConfig) {
        firebase.analytics();
    }
}

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// const firestore = firebase.firestore();
const db = firebase.firestore();
const functions = firebase.app().functions('europe-west3');
const firebaseAuth = firebase.auth();
const secondaryAuth = firebase.app('authSecondary').auth();
const storage = firebase.storage();
const fieldValue = firebase.firestore.FieldValue;
const Timestamp = firebase.firestore.Timestamp;

// if (isClientSide() && window.location.hostname == 'localhost') {
//     db.useEmulator('localhost', 8080);
//     functions.useEmulator('localhost', 5001);
//     // auth.useEmulator('http://localhost:9099/');
// }

// if (process.env.FIREBASE_EMULATORS) {
//     db.useEmulator('localhost', 8080);
//     functions.useEmulator('localhost', 5001);
//     // auth.useEmulator('http://localhost:9099/');
// }

export { db, functions, firebaseAuth, storage, fieldValue, firebase as firebaseApp, Timestamp, secondaryAuth };

export default firebase;
