import { createTheme } from '@mui/material/styles';

const typographyOverrides = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: 'bold',
                    fontSize: '2.875em',
                },
                h2: {
                    fontWeight: 'bold',
                    fontSize: '1.75rem',
                },
                h3: {
                    fontWeight: 'bold',
                    fontSize: '1.5em',
                },
                h4: {
                    fontWeight: 'bold',
                    fontSize: '1.275em',
                },
                h5: {
                    fontWeight: 'bold',
                    fontSize: '1.125',
                },
                h6: {
                    fontWeight: 'bold',
                    fontSize: '1.125',
                },
                subtitle1: {
                    fontSize: '1.275em',
                },
                subtitle2: {
                    fontSize: '1.125em',
                },
                caption: {
                    fontSize: '1em',
                },
                body1: {
                    fontSize: '0.875em',
                },
                body2: {
                    fontSize: '0.75em',
                    lineHeight: '20px',
                },
                overline: {
                    textTransform: 'none',
                    fontSize: '0.7em',
                    lineHeight: '12px',
                },
                root: {
                    '&.semibold': {
                        fontWeight: 600,
                    },
                },
            },
        },
    },
});

export default typographyOverrides;
