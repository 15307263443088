export const createState = <T = {}>(initialData: T = null) => {
    return {
        data: initialData,
        history: [],
        isInitial: true,
        redoHistory: [],
    };
};

export interface ContextState<T> {
    data: T;
    history: T[];
    isInitial: boolean;
    redoHistory: T[];
}
