import useLocalStorage from 'hooks/useLocalStorage';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useCartService } from './useCartService';

const CartContext = React.createContext<{ state?: CartContextState; dispatch?: React.Dispatch<Action> }>({});

export const CartServiceProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartContextStateReducer, {
        ...initialState,
    });

    const contextValue = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <CartContext.Provider value={contextValue}>
            <CartServiceProviderHandler>{children}</CartServiceProviderHandler>
        </CartContext.Provider>
    );
};

const CartServiceProviderHandler = ({ children }) => {
    const [cartId, setCartId] = useLocalStorage('_cart_id');
    const currentUser = useSelector((state: RootState) => state.currentUser.data);
    const { migrate } = useCartService();
    useEffect(() => {
        if (currentUser) {
            if (!cartId || cartId === 'undefined') {
                setCartId(currentUser.cartId);
                Cookies.set('userCart', currentUser.cartId, { expires: 1000 });
            } else {
                if (currentUser.cartId !== cartId) {
                    migrate(currentUser.cartId);
                }
                Cookies.set('userCart', currentUser.cartId, { expires: 1000 });

                // if (currentUser.cartId) {
                //     // console.log('migrating...');
                //     // migrate(cartId, currentUser.cartId);
                //     setCartId(currentUser.cartId);
                // } else {
                //     //raboti samo na refresh
                //     console.log('adding cart to user....');
                //     addCartToUser(cartId);
                // }
            }
        } else {
            // if (!cartId) {
            //     createCart().then(res => {
            //         setCartId(res.data);
            //     });
            // }
            // setCartId(undefined);
        }
    }, []);
    return children;
};

const useState = () => {
    const { state } = useContext(CartContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(CartContext);
    return dispatch;
};

export const useCartLoadingState = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        loading: state.loading,
        loadingPrice: state.loadingPrice,
        loadingDelivery: state.loadingDelivery,
        loadingType: state.loadingType,
        setLoading: loading => dispatch({ type: ActionType.LOADING_CHANGE, payload: loading }),
        setLoadingPrice: loading => dispatch({ type: ActionType.LOADING_PRICE_CHANGE, payload: loading }),
        setLoadingDelivery: loading => dispatch({ type: ActionType.LOADING_DELIVERY_CHANGE, payload: loading }),
        setLoadingType: loading => dispatch({ type: ActionType.LOADING_TYPE_CHANGE, payload: loading }),
    };
};

export enum ActionType {
    LOADING_CHANGE = 'loading',
    LOADING_PRICE_CHANGE = 'loading_price',
    LOADING_DELIVERY_CHANGE = 'loading_delivery',
    LOADING_TYPE_CHANGE = 'loading_type',
}

export type Action = {
    type: ActionType;
    payload: any;
};

export interface CartContextState {
    loading: boolean;
    loadingPrice: boolean;
    loadingDelivery: boolean;
    loadingType: boolean;
}

export const initialState: CartContextState = {
    loading: false,
    loadingPrice: false,
    loadingDelivery: false,
    loadingType: false,
};

const cartContextStateReducer = (state: CartContextState, action: Action) => {
    switch (action.type) {
        case ActionType.LOADING_CHANGE: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case ActionType.LOADING_PRICE_CHANGE: {
            return {
                ...state,
                loadingPrice: action.payload,
            };
        }
        case ActionType.LOADING_DELIVERY_CHANGE: {
            return {
                ...state,
                loadingDelivery: action.payload,
            };
        }
        case ActionType.LOADING_TYPE_CHANGE: {
            return {
                ...state,
                loadingType: action.payload,
            };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
