import { handleError, handleSuccess } from 'services/rest-api/utils';
import { joinQueryParameters } from 'services/utils';
import { ExecutorResponse } from 'types/rest-api';
import { QueryParameters } from 'types/routing';
import { getRequestAgent } from './agent';

export const restExecutor = (ENDPOINT_ROUTE: string, version: string = 'v1') => {
    const requestAgent = getRequestAgent(version);
    return {
        get: async (params?: QueryParameters, id?: string, headers?: any) => {
            return await execute(
                requestAgent.get,
                `${ENDPOINT_ROUTE}${joinQueryParameters(params)}${id ? `/${id}` : ''}`,
                true,
                headers
            );
        },
        post: async <P = {}>(data: P, headers?: any, id?: string, action?: string) => {
            return await execute(
                requestAgent.post,
                `${ENDPOINT_ROUTE}${id ? `/${id}` : ''}${action ? `/${action}` : ''}`,
                data,
                true,
                headers
            );
        },
        delete: async (id: string, headers?: any) => {
            return await execute(requestAgent.delete, `${ENDPOINT_ROUTE}/${id}`, true, headers);
        },
        patch: async <P = {}>(data: P, id: string, headers?: any) => {
            return await execute(requestAgent.patch, `${ENDPOINT_ROUTE}/${id}`, data, true, headers);
        },
    };
};

const execute = async (request, ...params): Promise<ExecutorResponse> => {
    try {
        const response = await request(...params);
        return handleSuccess(response);
    } catch (error) {
        if (error.response) {
            return handleError({ ...error.response, message: error.message });
        } else {
            return handleError({ status: 404, message: error.message || error });
        }
    }
};
