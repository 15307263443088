
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AppProps } from 'next/app';
import 'styles/globals.css';
import { Provider } from 'react-redux';
import { useStore } from 'redux/store';
import { ThemeProvider, Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from 'core/theme';
import React, { useEffect, useState } from 'react';
import 'styles/firebaseui-overrides.css';
import 'styles/slick-overrides.css';
import 'styles/customs.css';
import 'react-xflex-datepicker/dist/xflex-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'styles/fonts.css';
import 'styles/animations.css';
import { AuthContextProvider } from 'context/authentication';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/trackerLoader.css';
import 'react-phone-input-2/lib/style.css';
import { firebaseApp } from 'config/firebase';
import Cookies from 'js-cookie';
import useLocalStorage from 'hooks/useLocalStorage';
import { CartServiceProvider } from 'services/cart/CartServiceProvider';
import { HeadroomContextProvider } from 'context/headroom';
import createEmotionCache from '../src/createEmotionCache';
import { EmotionCache } from '@emotion/cache';
import { FiltersConsumer, FiltersContextProvider } from 'context/filters';
import { CacheProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import CookiePolicy from 'components/CookiePolicy';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { CurrentLocationProvider } from 'context/location';
import { DialogProvider } from 'context/dialog-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Geocode from 'react-geocode';
import GoogleMapsApiContainer from 'containers/google-maps/GoogleMapsApiContainer';
import { LocationProvider } from 'context/current-location';
import { ScrollerProvider } from 'context/scroller-context';
import { PartnersDeliveryProvider } from 'context/partners-delivery-context';
import 'react-dots-loader/index.css';
import GlobalLoading from 'components/GlobalLoading';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare global {
    interface Window {
        _REACT_CONTEXT_DEVTOOL: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
    const store = useStore((pageProps as any).initialReduxState!);
    const authenticated = (pageProps as any).authenticated;
    const [cartId, setCartId] = useLocalStorage('_cart_id');
    const [globalLoading, setGlobalLoading] = useState<boolean>(false);
    const [showGlobalLoader, setShowGlobalLoader] = useState<boolean>(false);

    useEffect(() => {
        if (!authenticated) {
            const userCart = Cookies.get('userCart');
            if (userCart) {
                if (cartId === userCart) setCartId(undefined);
                firebaseApp.auth().signOut();
                Cookies.remove('userCart');
            }
        }
    }, []);

    useEffect(() => {
        // const jssStyles = document.querySelector('#jss-server-side');
        // if (jssStyles) {
        //     jssStyles.parentElement!.removeChild(jssStyles);
        // }
        NProgress.configure({ ease: 'ease', speed: 500 });
        NProgress.configure({ trickleSpeed: 800 });
        NProgress.configure({ showSpinner: false });
        NProgress.configure({ parent: '#page-container' });

        Router.events.on('routeChangeStart', p => {
            if (p) {
                NProgress.start();
                NProgress.inc(0.8);

                setGlobalLoading(true);
                setTimeout(() => {
                    setShowGlobalLoader(true);
                }, 10);
            }
        });
        Router.events.on('routeChangeComplete', () => {
            NProgress.done();

            setTimeout(() => {
                setShowGlobalLoader(false);
                setTimeout(() => {
                    setGlobalLoading(false);
                }, 10);
            }, 500);
        });
        Router.events.on('routeChangeError', (...e) => {
            console.log('error route', ...e);
            NProgress.done();

            setTimeout(() => {
                setShowGlobalLoader(false);
                setTimeout(() => {
                    setGlobalLoading(false);
                }, 10);
            }, 500);
        });

        setTimeout(() => {
            NProgress.done();
        }, 10000);
    }, []);

    useEffect(() => {
        Geocode.setApiKey('AIzaSyAfs7o61AFwX4VD9pyAdjWi2Z1dLuNIcWQ');
        Geocode.setLanguage('en');
    }, []);

    return (
        <CacheProvider value={emotionCache}>
            {/* <StyledEngineProvider injectFirst> */}
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <CookiePolicy />
                    <CssBaseline />
                    <Provider store={store}>
                        <CartServiceProvider>
                            <AuthContextProvider authenticated={(pageProps as any).authenticated} uid={(pageProps as any).uid}>
                                <HeadroomContextProvider>
                                    <CurrentLocationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <FiltersContextProvider>
                                                <LocationProvider>
                                                    <DialogProvider>
                                                        <ScrollerProvider>
                                                            <PartnersDeliveryProvider>
                                                                <ErrorBoundary>
                                                                    <Component {...pageProps} />
                                                                    <FiltersConsumer />
                                                                    {globalLoading && (
                                                                        <GlobalLoading showGlobalLoader={showGlobalLoader} />
                                                                    )}
                                                                </ErrorBoundary>
                                                            </PartnersDeliveryProvider>
                                                        </ScrollerProvider>
                                                    </DialogProvider>
                                                </LocationProvider>
                                            </FiltersContextProvider>
                                        </LocalizationProvider>
                                    </CurrentLocationProvider>
                                </HeadroomContextProvider>
                            </AuthContextProvider>
                        </CartServiceProvider>
                    </Provider>
                </SnackbarProvider>
            </ThemeProvider>
            {/* </StyledEngineProvider> */}
        </CacheProvider>
    );
}

// export function reportWebVitals(metric) {
//     console.log(metric);
// }

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  