import { Variant } from '@mui/material/styles/createTypography';
import Typography, { TypographyProps } from '@mui/material/Typography';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { getVariant, getWeight } from 'services/typography';
import { TextSizes, TextSizesLiteral, TextWeights, TextWeightsLiteral } from 'types/typography';

interface IHeadingProps extends TypographyProps {
    children?: ReactNode;
}

const Heading = ({ children, ...rest }: IHeadingProps) => {
    return <Typography {...rest}>{children}</Typography>;
};

const createHeading = (props: IHeadingProps, variant: Variant) => Heading({ ...props, variant });

export const Headings = {
    H1: (props: IHeadingProps) => createHeading(props, 'h1'),
    H2: (props: IHeadingProps) => createHeading(props, 'h2'),
    H3: (props: IHeadingProps) => createHeading(props, 'h3'),
    H4: (props: IHeadingProps) => createHeading(props, 'h4'),
    H5: (props: IHeadingProps) => createHeading(props, 'h5'),
    H6: (props: IHeadingProps) => createHeading(props, 'h6'),
};

type TextWeightsProps = { [key in keyof typeof TextWeights]?: boolean };
type TextProps = {
    [key in keyof (typeof TextSizes & typeof TextWeights)]?: boolean;
};

export interface ITextProps extends TextProps, TypographyProps {
    children?: ReactNode;
}

export const Text = ({
    large = false,
    medium = false,
    caption = false,
    normal = true,
    small = false,
    smaller = false,
    semibold = false,
    children,
    ...rest
}: ITextProps) => {
    const variant = getVariant({ large, medium, caption, normal, small, smaller });

    delete rest.regular;

    return (
        <Typography
            {...{ ...rest, variant }}
            className={clsx([
                rest?.className,
                {
                    semibold: semibold,
                },
            ])}
            component="span"
        >
            {children}
        </Typography>
    );
};

export interface ISizedTextProps extends TextWeightsProps, TypographyProps {
    children?: ReactNode;
}

const createText = (props: ISizedTextProps, size: TextSizesLiteral, weight: TextWeightsLiteral) =>
    Text({ normal: false, [size]: true, [weight]: true, ...props });

export const LargeText = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'large', getWeight({ regular, semibold }));

export const MediumText = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'medium', getWeight({ regular, semibold }));

export const Caption = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'caption', getWeight({ regular, semibold }));

export const NormalText = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'normal', getWeight({ regular, semibold }));

export const SmallText = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'small', getWeight({ regular, semibold }));

export const SmallerText = ({ regular, semibold, ...rest }: ISizedTextProps) =>
    createText(rest, 'smaller', getWeight({ regular, semibold }));
