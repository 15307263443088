import { useMemo } from 'react';
import reducer from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

let store: any;

const initStore = (preloadedState?: any) => {
    return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));
};

export const initializeStore = (preloadedState?: any) => {
    let _store = store ?? initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;
    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export const rrfConfig = {
    // userProfile: 'mode/development/customers',
    // useFirestoreForProfile: true,
};

export function useStore(initialState?: any) {
    const store = useMemo(() => initializeStore(initialState), [initialState]);
    return store;
}
