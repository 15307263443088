import { combineReducers } from 'redux';
import { basicReducer } from './basicReducer';
import { USER_LOGIN_INFO_HANDLE, USER_INFO_GET, CURRENT_USER_GET } from 'redux/constants/users';
import {
    DialogState,
    Globals,
    MapInfo,
    SignInProcessInfo,
    UserInfo,
    UsersLoginInfo,
    ValidationInfo,
} from 'redux/types/internalTypes';
import { PromotedRestaurants, ReduxState, RestaurantsState, SectionsState } from '../types/reducerTypes';
import { VALIDATION_INFO_HANDLE } from 'redux/constants/validation';
import { VALIDATION_INFO_DEFAULT } from 'types/validation';
import { SIGN_IN_PROCESS_HANDLE } from 'redux/constants/auth';
import {
    dialogsStateDefault,
    GLOBALS_DEFAULT,
    MAP_INFO_DEFAULT,
    RESTAURANTS_DEFAULT,
    SECTIONS_DEFAULT,
    SIGN_IN_PROCESS_DEFAULT,
    USERS_LOGIN_INFO_DEFAULT,
    USER_CART_DEFAULT,
} from 'redux/defaults';
import { Company, Reservation, Restaurant } from 'klikni-jadi-shared-stuff';
import {
    RESTAURANTS_GET,
    RESTAURANT_GET,
    RESTAURANT_MENU_GET,
    RECOMMENDED_RESTAURANTS_GET,
    FAVORITE_RESTAURANTS_GET,
    PROMOTED_RESTAURANTS_GET,
} from 'redux/constants/restaurants';
import { GLOBALS_HANDLE } from 'redux/constants/global';
import { scroller } from './custom/scrollerReducer';
import { MAP_OPENED_PROPERTIES_HANDLE } from 'redux/constants/map';
import { USER_CART_HANDLE } from 'redux/constants/cart';
import { Customer } from 'klikni-jadi-shared-stuff';
import { FoodMenu } from 'klikni-jadi-shared-stuff';
import { SETTINGS_GET } from 'redux/constants/settings';
import { Order } from 'klikni-jadi-shared-stuff';
import { ORDERS_GET } from 'redux/constants/orders';
import { DIALOG_INFO_HANDLE } from 'redux/constants/dialog';
import { Settings } from 'klikni-jadi-shared-stuff';
import { RESERVATIONS_GET } from 'redux/constants/reservations';
import { Review } from 'klikni-jadi-shared-stuff';
import { REVIEWS_GET } from 'redux/constants/reviews';
import { Banner } from 'klikni-jadi-shared-stuff';
import { BANNERS_GET } from 'redux/constants/banners';
import { Cart as UserCart } from 'klikni-jadi-shared-stuff';
import { SECTIONS_GET } from 'redux/constants/sections';

const rootReducer = combineReducers<ReduxState>({
    usersLoginInfo: basicReducer<UsersLoginInfo>(USER_LOGIN_INFO_HANDLE, USERS_LOGIN_INFO_DEFAULT),
    validationInfo: basicReducer<ValidationInfo>(VALIDATION_INFO_HANDLE, VALIDATION_INFO_DEFAULT),
    signInProcessInfo: basicReducer<SignInProcessInfo>(SIGN_IN_PROCESS_HANDLE, SIGN_IN_PROCESS_DEFAULT),
    userInfo: basicReducer<UserInfo>(USER_INFO_GET),
    restaurants: basicReducer<RestaurantsState>(RESTAURANTS_GET, RESTAURANTS_DEFAULT),
    sections: basicReducer<SectionsState>(SECTIONS_GET, SECTIONS_DEFAULT),
    details: basicReducer<Restaurant>(RESTAURANT_GET),
    globals: basicReducer<Globals>(GLOBALS_HANDLE, GLOBALS_DEFAULT),
    mapInfo: basicReducer<MapInfo>(MAP_OPENED_PROPERTIES_HANDLE, MAP_INFO_DEFAULT),
    scroller,
    currentUser: basicReducer<Customer>(CURRENT_USER_GET),
    restaurantMenu: basicReducer<FoodMenu[]>(RESTAURANT_MENU_GET),
    settingsFilters: basicReducer<Settings>(SETTINGS_GET),
    userOrders: basicReducer<Order[]>(ORDERS_GET),
    dialogInfo: basicReducer<DialogState>(DIALOG_INFO_HANDLE, dialogsStateDefault),
    favoriteRestaurants: basicReducer<Restaurant[]>(FAVORITE_RESTAURANTS_GET),
    reservations: basicReducer<Reservation[]>(RESERVATIONS_GET),
    reviews: basicReducer<Review[]>(REVIEWS_GET),
    banners: basicReducer<Banner>(BANNERS_GET),
    userCart: basicReducer<UserCart>(USER_CART_HANDLE, USER_CART_DEFAULT),
    recommendedRestaurants: basicReducer<Restaurant[]>(RECOMMENDED_RESTAURANTS_GET),
    promotedRestaurants: basicReducer<PromotedRestaurants>(PROMOTED_RESTAURANTS_GET),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
