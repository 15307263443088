import { Action } from '../../core/design/Action';
import { ActionType } from '../types/actionTypes';

export const SCROLL_HEADER_SEARCH_ZONE: ActionType = {
    entity: 'SCROLL_ABOVE_LIMIT',
    action: Action.HANDLE,
};
export const SCROLL_NO_HEADER_SEARCH_ZONE: ActionType = {
    entity: 'SCROLL_BELOW_LIMIT',
    action: Action.HANDLE,
};

export const SCROLL_HEADER_TRANSPARENT_ZONE: ActionType = {
    entity: 'SCROLL_TRANSPARENT_HEADER',
    action: Action.HANDLE,
};
export const SCROLL_NO_HEADER_TRANSPARENT_ZONE: ActionType = {
    entity: 'SCROLL_NO_TRANSPARENT_HEADER',
    action: Action.HANDLE,
};
