import { cloneDeep } from 'lodash';
import { Action, getActionTypeMapping } from './generic-actions';
import { ContextState } from './generic-state';

export const createReducer =
    <T>() =>
    (state: ContextState<T>, action: Action<T>) => {
        if (action.type === '_set_data') {
            const _data = action.payload as T;
            const _history =
                state.isInitial || !action.addInHistory ? [...state.history] : [...state.history, cloneDeep(state.data)];
            return {
                ...state,
                data: _data,
                history: _history,
                isInitial: action.isInitial,
                redoHistory: [],
            };
        }
        if (action.type === '_undo') {
            const _history = [...state.history];
            const _data = _history.pop() as T;
            const _redo = [...state.redoHistory, cloneDeep(state.data)];
            if (_data) {
                return {
                    ...state,
                    data: _data,
                    history: _history,
                    redoHistory: _redo,
                };
            }
            return { ...state };
        }
        if (action.type === '_redo') {
            const _redo = [...state.redoHistory];
            const _data = _redo.pop() as T;
            const _history = [...state.history, cloneDeep(state.data)];
            if (_data) {
                return {
                    ...state,
                    data: _data,
                    history: _history,
                    redoHistory: _redo,
                };
            }
            return { ...state };
        }
        if (action.type === '_clear_histories') {
            return {
                ...state,
                history: [],
                redoHistory: [],
            };
        }
        if (action.type === '_initialize') {
            const _data = action.payload as T;
            return {
                ...state,
                data: _data,
                isInitial: false,
                redoHistory: [],
            };
        }
        const typeMapping = getActionTypeMapping<T>(state.data);
        const key = typeMapping[action.type];
        const _data = action.payload;
        const _history = state.isInitial || !action.addInHistory ? [...state.history] : [...state.history, cloneDeep(state.data)];
        return {
            ...state,
            data: {
                ...state.data,
                [key as string]: _data,
            },
            history: _history,
            isInitial: action.isInitial,
            redoHistory: [],
        };
    };
