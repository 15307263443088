type Remapped<T> = {
    [K in keyof T & string as `set_${K & string}`]: K;
};

export type ActionType<T> = keyof Remapped<T> | '_set_data' | '_undo' | '_initialize' | '_redo' | '_clear_histories';

export const getActionTypeMapping = <T>(state: T) => {
    return getKeys(state).reduce<Remapped<T>>((acc, k) => ({ ...acc, [`set_${String(k)}`]: k }), {} as Remapped<T>);
};

export const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o || {});

export interface Action<T = {}> {
    type: ActionType<T>;
    payload?: Partial<T> | T;
    isInitial?: boolean;
    addInHistory?: boolean;
}
