import { createGenericContext } from './generic-context';

export interface State {
    [x: string]: {
        deliveryFee: number;
        distance: number;
    };
}

const GenericContext = createGenericContext<State>({});

export const PartnersDeliveryProvider = GenericContext.Provider;

export const usePartnersDeliveryContext = GenericContext.useGenericContext;
