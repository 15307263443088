import { useEffect, EffectCallback, DependencyList } from 'react';
import React from 'react';
import { createContext, Dispatch, useContext, useMemo, useReducer } from 'react';
import { Action } from './generic-actions';
import { createReducer } from './generic-reducer';
import { ContextState, createState } from './generic-state';

export const createGenericContext = <T extends unknown = {}, V extends object = {}>(
    initialState: T = null,
    additional?: (state?: ContextState<T>, dispatch?: Dispatch<Action<T>>) => V
) => {
    const Context = createContext<{
        state?: ContextState<T>;
        dispatch?: Dispatch<Action<T>>;
    }>({
        state: {
            data: null,
            history: [],
            isInitial: true,
            redoHistory: [],
        },
    });

    const Provider = ({
        children,
        name = '',
        contextValue,
    }: {
        contextValue: {
            state: {
                data: T;
                history: T[];
                isInitial: boolean;
                redoHistory: T[];
            };
            dispatch: React.Dispatch<Action<T>>;
        };
        children: any;
        name: string;
    }) => {
        return (
            <Context.Provider value={contextValue} key={name}>
                {children}
            </Context.Provider>
        );
    };

    const enhanceProvider = (
        onMount?: (dispatch: React.Dispatch<Action<T>>, state?: ContextState<T>) => EffectCallback,
        onMountDependencies: DependencyList = []
    ) => {
        return ({ children, name = '', value = null }) => {
            const [state, dispatch] = useReducer(createReducer<T>(), createState<T>(value || initialState));
            const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

            useEffect(onMount ? onMount?.(dispatch, state) : () => {}, [dispatch, ...onMountDependencies]);

            return <Provider {...{ children, name, contextValue }} />;
        };
    };

    return {
        Provider: enhanceProvider(),
        Consumer: Context.Consumer,
        enhanceProvider,
        useGenericContext: () => {
            const { state, dispatch } = useContext(Context);
            return {
                data: state.data,
                history: state.history,
                redoHistory: state.redoHistory,
                update: <K extends keyof ExtendedK<T>>(property: K, payload: ExtendedK<T>[K], addInHistory: boolean = false) => {
                    if (property === '__data__') {
                        dispatch?.({ type: `_set_data`, payload: payload as T, isInitial: false, addInHistory });
                    } else {
                        dispatch?.({
                            type: `set_${property as keyof T & string}`,
                            payload: payload as T,
                            isInitial: false,
                            addInHistory,
                        });
                    }
                },
                reset: () => {
                    dispatch?.({ type: '_set_data', payload: initialState, isInitial: false });
                },
                undo: () => {
                    dispatch?.({ type: '_undo' });
                },
                redo: () => {
                    dispatch?.({ type: '_redo' });
                },
                clearHistories: () => {
                    dispatch?.({ type: '_clear_histories' });
                },
                initialize: (payload: ExtendedK<T>['__data__']) => {
                    dispatch?.({ type: `_initialize`, payload });
                },
                ...additional?.(state, dispatch),
            };
        },
    };
};

const o = {};
type A<T> = {
    [K in keyof typeof o]: T;
};

//{
//     data: T;
//     update: <K extends keyof ExtendedK<T>>(property: K, payload: ExtendedK<T>[K]) => void;
//     reset: () => void;
// }

type ExtendedK<T> = T & {
    __data__: T;
};
