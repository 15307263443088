import { db } from 'config/firebase';
import { Query, CollectionReference, DocumentData } from '@firebase/firestore-types';
import { CollectionOptions, Subcollection } from 'types/firestore';
import { getSubcollectionsForDocument } from 'hooks/useFirestore/docApi';

export const collection = async <T>(path: string, options?: CollectionOptions) => {
    const query = getQuery(path, options);

    const snapshots = await query.get();
    if (!snapshots.docs) {
        return null;
    }
    return snapshots.docs.map(doc => {
        return {
            id: doc.id,
            ...doc.data(),
        } as unknown as T;
    });
};

export const createDoc = async (collection: string, values: any) => {
    return getFirestoreRef(collection)
        .add(values)
        .then(val => val.id);
};

export const doc = async <T>(collection: string, id: string, subcollectionsOptions?: Subcollection[]) => {
    const document = await getFirestoreRef(collection).doc(id).get();
    if (!document.exists) {
        return null;
    }
    const result: DocumentData = { id: document.id, ...document.data() };
    const subcollections = await getSubcollectionsForDocument(document, subcollectionsOptions);
    return { ...result, ...subcollections } as unknown as T;
};

export const updateDoc = <T = {}>(collection: string, id: string, values: Partial<T>) => {
    return getFirestoreRef(collection).doc(id).set(values, { merge: true });
};

export const deleteDoc = (collection: string, id: string) => {
    return getFirestoreRef(collection).doc(id).delete();
};
export const generateId = (path: string) => {
    const ref = getFirestoreRef(path).doc();
    return ref.id;
};

export const getQuery = (collection: string, options?: CollectionOptions) => {
    const baseQuery: CollectionReference = collection === 'root' ? getRootRef() : getFirestoreRef(collection);
    let query: Query = baseQuery;
    if (options && options.queries) {
        const { queries } = options;
        queries.forEach(({ attribute, operator, value }) => {
            query = query.where(attribute, operator, value);
        });
    }

    if (options && options.sort) {
        const { attribute, order } = options.sort;
        query = query.orderBy(attribute, order);
    }

    if (options && options.limit) {
        query = query.limit(options.limit);
    }

    return query;
};

export const getFirestoreRef = (path: string): CollectionReference<DocumentData> => {
    // const root = 'mode/development';
    return db.collection(`${path}`);
};

export const getRootRef = () => {
    return db.collection('mode');
};
