import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTheme } from '@mui/private-theming';
import { Box } from '@mui/system';
import firebase from 'firebase';
import Cookies from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { LargeText, MediumText } from './atoms/Typography';

interface ICookiePolicyProps {}

const CookiePolicy = ({}: ICookiePolicyProps) => {
    const [marketingCookie, setmarketingCookie] = useState<boolean>(true);
    const [analyticsCookie, setAnalyticsCookie] = useState<boolean>(true);

    const storage = firebase.storage();
    const { t } = useTranslation('common');
    const theme = useTheme();

    const handleChange = (e, cookieName: string) => {
        if (cookieName === 'marketing') {
            setmarketingCookie(e.target.checked);
        } else {
            setAnalyticsCookie(e.target.checked);
        }
    };

    const handleAcceptCookies = () => {
        Cookies.set('necessaryCookies', 'true', { path: '/' });
        if (marketingCookie) {
            Cookies.set('marketingCookie', 'true', { path: '/' });
        } else {
            Cookies.set('marketingCookie', 'false', { path: '/' });
        }

        if (analyticsCookie) {
            Cookies.set('analyticsCookie', 'true', { path: '/' });
        } else {
            Cookies.set('analyticsCookie', 'false', { path: '/' });
        }
    };

    const handleLegalInformationClick = () => {
        storage
            .ref('policy/Politika na kolacinja.pdf')
            .getDownloadURL()
            .then(url => window.open(url, '_blank'))
            .catch(e => console.log('error while getting terms and conditions pdf ', e));
    };

    return (
        <CookieConsent
            buttonText={t('agree')}
            buttonStyle={{ backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '20px' }}
            style={{ backgroundColor: theme.palette.grey[50] }}
            onAccept={handleAcceptCookies}
        >
            <Box>
                <Box>
                    <LargeText sx={{ fontWeight: '500', color: 'black' }}>{t('cookieMessage')} </LargeText>
                    {/* <MediumText
                        sx={{ textDecoration: 'underline', ':hover': { cursor: 'pointer' } }}
                        color="lightblue"
                        onClick={handleLegalInformationClick}
                    >
                        {' '}
                        {t('readMore')}
                    </MediumText> */}
                </Box>
                <Box>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled
                                    defaultChecked
                                    sx={{
                                        color: 'white',
                                    }}
                                />
                            }
                            label={t('necessaryCookies')}
                            sx={{
                                fontSize: '20px',
                                color: 'black',
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            onChange={e => handleChange(e, 'marketing')}
                            label={t('marketing')}
                            sx={{ fontSize: '20px', color: 'black' }}
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            onChange={e => handleChange(e, 'analytics')}
                            label={t('analytics')}
                            sx={{ fontSize: '20px', color: 'black' }}
                        />
                    </FormGroup>
                </Box>
            </Box>
        </CookieConsent>
    );
};

export default CookiePolicy;
