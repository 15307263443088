import { Cart, CartItem, FeeType, FoodItem, Order, OrderType, Payment, Restaurant, WorkingHours } from 'klikni-jadi-shared-stuff';
import { isEmpty, map, min } from 'lodash';
import moment, { Moment } from 'moment';

export const deleteUndefined = (obj: Record<string, any> | undefined): void => {
    if (obj) {
        Object.keys(obj).forEach((key: string) => {
            if (obj[key] && typeof obj[key] === 'object') {
                deleteUndefined(obj[key]);
            } else if (typeof obj[key] === 'undefined') {
                delete obj[key]; // eslint-disable-line no-param-reassign
            }
        });
    }
};

export const isServerSide = () => typeof window === 'undefined';
export const isClientSide = () => !isServerSide();

export const constructUrlFromPathnameQuery = (pathname: string, query: Record<string, any>) => {
    return `${pathname}${
        Object.keys(query).length > 0
            ? `?${Object.entries(query)
                  .map(([key, value]) => `${key}=${value}`)
                  .join('&')}`
            : ''
    }`;
};

export const spliceInHalf = list => {
    const half = Math.ceil(list.length / 2);
    const firstHalf = [...list].splice(0, half);
    const secondHalf = [...list].splice(half, list.length);
    return [firstHalf, secondHalf];
};

export const eachHalfHour = (t1: string, t2: string) => {
    // eslint-disable-next-line prefer-const
    const toInt = (time: string) => {
            const args: number[] = time.split(':').map(parseFloat);
            return ((h: number, m: number) => h * 2 + m / 30)(args[0], args[1]);
        },
        // eslint-disable-next-line prefer-const
        toTime = (int: number) => [Math.floor(int / 2), int % 2 ? '30' : '00'].join(':'),
        range = (from: number, to: number) =>
            Array(to - from + 1)
                .fill(0)
                .map((_, i) => from + i);
    const args: number[] = [t1, t2].map(toInt);
    return range(args[0], args[1]).map(toTime);
};

export const elementInViewport = el => {
    let top = el.offsetTop;
    // let left = el.offsetLeft;
    // var width = el.offsetWidth;
    // var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        // left += el.offsetLeft;
    }

    return (
        top < window.pageYOffset + window.innerHeight - 150
        // left < window.pageXOffset + window.innerWidth &&
        // top + height > window.pageYOffset &&
        // left + width > window.pageXOffset
    );
};

export const joinQueryParameters = (query: Record<string, any>) =>
    query
        ? Object.values(query).filter(v => v).length > 0
            ? `?${Object.entries(query)
                  .filter(([, v]) => v)
                  .map(([key, value]) => `${key}=${value}`)
                  .join('&')}`
            : ''
        : '';

// Helper
const StringIsNumber = value => isNaN(Number(value)) === false;

// Turn enum into array
export const enumToArray = enumme => {
    return Object.keys(enumme)
        .filter(StringIsNumber)
        .map(key => enumme[key]);
};

//Flatten nested objects
export const countInNestedObject = (obj, nestedKey) => {
    let counter = 0;
    Object.values(obj || {}).forEach(val => {
        counter += Object.values(val[nestedKey]).length;
    });
    return counter;
};

export const getMinutesFrom = (start: Moment) => {
    const duration = moment.duration(moment().diff(start));
    return duration.asMinutes();
};

export const getVelocity = (t: number, s: number) => s / t;

export const getDistancePassed = (velocity: number, time: number) => velocity * time;

export const filterRecommendedRestaurants = (restaurants: Restaurant[], zoom: number) => {
    return zoom < 16 ? restaurants.filter(res => res?.sections?.isSponsored) : restaurants;
};
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getMenuItemVisiblePrice = (item: FoodItem, cartType: OrderType, independentDelivery: boolean, payment: Payment, promotionService?: string[]) => {
    let feeType: FeeType = 'delivery';

    if (cartType === 'delivery' && !independentDelivery) {
        feeType = 'delivery';
    } else if (cartType === 'delivery' && independentDelivery) {
        feeType = 'selfDelivery';
    } else if (cartType === 'takeout' && payment === 'cash') {
        feeType = 'takeoutCash';
    } else {
        feeType = 'takeoutOnline';
    }

    // console.log('promotionService', promotionService);
    
    if (promotionService && promotionService.length > 0) {
        if (promotionService.includes('delivery')) {
            feeType = 'delivery';
        } else if (promotionService.includes('takeout')) {
            feeType = 'takeoutCash';
        } else if (promotionService.includes('inhoouse')) {
            feeType = 'inhouseCash';
        }
    }

    if (isEmpty(item.portions))
        return {
            discountedPrice: item.discountedPrice?.[feeType],
            finalPrice: item.finalPrice?.[feeType],
            basePrice: item.basePrice, // * Needed for promotion items
        };

    return {
        discountedPrice: min(map(item.portions, portion => portion.discountedPrice?.[feeType])),
        finalPrice: min(map(item.portions, portion => portion.finalPrice?.[feeType])),
        basePrice: min(map(item.portions, portion => portion.basePrice)),
    };
};

export const getCartItemVisiblePrice = (item: CartItem, feeType: string) => {
    return item.prices.itemCost;
};

export const getOrderPrices = (order: Order) => {
    const walletMoney = order.wallet || 0;
    const discount = order.takeoutDiscount || 0;
    const roundup = order.roundup || 0;
    const itemsTotal = order.itemsTotalCost;
    const deliveryFee = order.deliveryFee || 0;
    const priorityFee = order.priorityFee || 0;
    const serviceFee = order.serviceFee || 0;
    const onlineFee = order.onlineFee || 0;
    const tip = order.tip || 0;

    return {
        total: order?.payment === 'online' ? order.total + onlineFee : order.total,
        itemsTotal,
        deliveryFee,
        priorityFee,
        tip,
        roundup,
        discount,
        serviceFee,
        walletMoney,
    };
};

export const getIsOpenNowForHours = (hours: WorkingHours) => {
    const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const shorthandDaysMapper = {
        mon: { index: 1 },
        tue: { index: 2 },
        wed: { index: 3 },
        thu: { index: 4 },
        fri: { index: 5 },
        sat: { index: 6 },
        sun: { index: 7 },
    };

    const workingHours = weekdays
        .map(day => ({
            periods: hours?.delivery[day] || [],
            index: shorthandDaysMapper[day].index,
        }))
        .sort((a, b) => a.index - b.index);

    const tomorrow = moment().isoWeekday();

    const todayPeriods = workingHours[tomorrow - 1].periods.map(period => ({
        from: +period.from,
        to: +period.to,
    }));

    const currentTime = +moment().format('HHmm');

    return !!todayPeriods.find(t => currentTime < t.to && currentTime > t.from);
};

export const roundedToFixed = (input, digits) => {
    const rounder = Math.pow(10, digits);
    return (Math.round(input * rounder) / rounder).toFixed(digits);
};
