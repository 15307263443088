import { Action, ActionType } from './local-actions';
import { FiltersState } from './local-state';

export const filtersStateReducer = (state: FiltersState, action: Action): FiltersState => {
    switch (action.type) {
        case ActionType.SET_ALL_FILTERS: {
            const { cuisineId, occupationId, tagId, amenitiesIds, type } = action.payload;

            return {
                cuisineId,
                occupationId,
                tagId,
                amenitiesIds,
                type,
                selectedFilters: {
                    cuisineId,
                    occupationId,
                    tagId,
                    amenitiesIds,
                    type,
                },
            };
        }
        case ActionType.SET_SELECTED_FILTERS: {
            const { cuisineId, occupationId, tagId, amenitiesIds, type } = action.payload;

            return {
                ...state,
                selectedFilters: {
                    cuisineId,
                    occupationId,
                    tagId,
                    amenitiesIds,
                    type,
                },
            };
        }
        case ActionType.SET_CUISINE: {
            const { cuisineId } = action.payload;

            return {
                ...state,
                cuisineId,
            };
        }
        case ActionType.SET_OCCUPATION: {
            const { occupationId } = action.payload;

            return {
                ...state,
                occupationId,
            };
        }

        case ActionType.SET_TAG: {
            const { tagId } = action.payload;

            return {
                ...state,
                tagId,
            };
        }

        case ActionType.SET_TYPE: {
            const { type } = action.payload;

            return {
                ...state,
                type,
            };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
