import { pickBy } from 'lodash';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { isClientSide } from 'services/utils';
import { RoutingRoute } from 'types/routing';

export const useRouting = () => {
    const router = useRouter();
    const routerQuery = useMemo(() => {
        let rq = {};

        if (isClientSide()) {
            const splitted = router.asPath.split('?');
            if (splitted.length > 1) {
                //if there are query params, construct an object from the string
                rq = splitted[1].split('&').reduce((acc: Record<string, any>, param) => {
                    acc[param.split('=')[0]] = param.split('=')[1];
                    return acc;
                }, {});
            }
        }

        return rq;
    }, [router.asPath]);

    return {
        push: ({ to, queries }: RoutingRoute) => {
            const _query = { ...router.query };
            if (queries) {
                Object.entries(queries).forEach(([key, value]) => {
                    if (!value) {
                        delete _query[key];
                    } else _query[key] = value;
                });
            }
            let _pathname = router.pathname;
            if (to) {
                _pathname = to;
            }

            router.push({ pathname: _pathname, query: pickBy(_query) });
        },
        replace: ({ to, queries }: RoutingRoute) => {
            const _query = { ...router.query };
            if (queries) {
                Object.entries(queries).forEach(([key, value]) => {
                    if (!value) {
                        delete _query[key];
                    } else _query[key] = value;
                });
            }
            let _pathname = router.pathname;
            if (to) {
                _pathname = to;
            }

            router.replace({ pathname: _pathname, query: pickBy(_query) });
        },
        hasQuery: (query: string) => routerQuery.hasOwnProperty.call(query),
        hasQueryValue: (query: string, value: string) => routerQuery.hasOwnProperty.call(query) && routerQuery[query] === value,
        queries: routerQuery as any,
    };
};
