import { Variant } from '@mui/material/styles/createTypography';
import { TextWeightsLiteral } from 'types/typography';

export const propsToVariants = {
    large: 'subtitle1',
    medium: 'subtitle2',
    caption: 'caption',
    normal: 'body1',
    small: 'body2',
    smaller: 'overline',
};

export const getVariant = (props: Record<string, boolean>): Variant => {
    return Object.entries(props).reduce((variant, [prop, value]) => {
        if (value) return (variant = propsToVariants[prop]);
        return variant;
    }, 'body1');
};

export const getWeight = (props: Record<TextWeightsLiteral, boolean>): TextWeightsLiteral => {
    return Object.entries(props).reduce((weight: TextWeightsLiteral, [prop, value]: [TextWeightsLiteral, boolean]) => {
        if (value) return prop;
        return weight;
    }, 'regular' as TextWeightsLiteral);
};
