export const handleSuccess = response => {
    const data = response.data;
    return {
        success: true,
        status: response.status,
        data,
    };
};

export const handleError = response => {
    return {
        data: null,
        ...response,
        success: false,
        status: response.status,
    };
};
