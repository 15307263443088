import { ActionStatus } from 'core/design/ActionStatus';
import { RootState } from 'redux/reducers';
import { ActionType, GenericActions, UpdateActions } from '../../types/actionTypes';
import { buildActionType } from './buildActionType';

export type ActionMutator = (state: RootState, data: any) => Promise<any>;

export const createFirestoreActions = <P = {}>(TYPE: ActionType, mutator?: ActionMutator): GenericActions<P> => {
    return {
        success: (data: any) => async (dispatch, getState) => {
            const innerData = mutator ? await mutator(getState(), data) : data;
            dispatch({
                type: buildActionType(TYPE, ActionStatus.DONE),
                payload: innerData,
            });
        },
        loading: () => async dispatch => {
            dispatch({
                type: buildActionType(TYPE, ActionStatus.START),
            });
        },
        error: (message: string) => async dispatch => {
            dispatch({
                type: buildActionType(TYPE, ActionStatus.FAILED),
                payload: message,
            });
        },
        reset: () => async dispatch => {
            dispatch({
                type: buildActionType(TYPE, ActionStatus.RESET),
            });
        },
        done: () => async (dispatch, getState) => {
            dispatch({
                type: buildActionType(TYPE, ActionStatus.DONE),
                payload: getState().userCart.data,
            });
        },
    };
};

const constructNewState = (current, keysPath, index, data) => {
    if (index === keysPath.length) {
        return { ...current.data, ...data };
    }
    return constructNewState(current[keysPath[index]], keysPath, index + 1, data);
};

export const createUpdateActions = <P = {}>(TYPE: ActionType, keysLiteral: string): UpdateActions<P> => ({
    update: (data?: Partial<P>) => async (dispatch, getState) => {
        const state = getState();
        const keysArray = keysLiteral.split('.');
        const newState = constructNewState(state, keysArray, 0, data);
        dispatch({
            type: buildActionType(TYPE, ActionStatus.DONE),
            payload: (newState as unknown) as P,
        });
    },
    loading: () => async dispatch => {
        dispatch({
            type: buildActionType(TYPE, ActionStatus.START),
        });
    },
    error: (message: string) => async dispatch => {
        dispatch({
            type: buildActionType(TYPE, ActionStatus.FAILED),
            payload: message,
        });
    },
    reset: () => async dispatch => dispatch({ type: buildActionType(TYPE, ActionStatus.RESET) }),
});
