import { Customer, Wallet } from 'klikni-jadi-shared-stuff';
import { CURRENT_USER_GET } from 'redux/constants/users';
import { createFirestoreActions } from '../service/actionBuilder';

export const currentUserFirestoreActions = createFirestoreActions<Customer>(CURRENT_USER_GET, async (state, data) => {
    return { ...(state.currentUser.data || {}), ...data };
});

export const currentUserWalletFirestoreActions = createFirestoreActions<Wallet>(CURRENT_USER_GET, async (state, data: Wallet) => {
    return { ...(state.currentUser.data || {}), wallet: data };
});
