import { ActionStatus } from 'core/design/ActionStatus';
import { buildActionType } from '../actions/service/buildActionType';
import { StateStatus } from 'core/design/StateStatus';
import { ActionType } from '../types/actionTypes';
import { AnyAction } from 'redux';

const initialState = {
    data: null,
    errors: null,
    status: StateStatus.NOT_INITIALIZED,
    version: 0,
    entity: '',
};

export interface BasicState<T> {
    data: T;
    status: number;
    version: number;
    entity: string;
    errors: any;
}

const basicReducer = <T extends {}>(actionType: ActionType, initialData: T = null) => (
    state = { ...initialState, data: initialData },
    action: AnyAction
): BasicState<T> => {
    switch (action.type) {
        case buildActionType(actionType, ActionStatus.START):
            return {
                ...state,
                status: StateStatus.LOADING,
                version: state.version,
                entity: actionType.entity,
                data: state.data,
            };

        case buildActionType(actionType, ActionStatus.DONE):
            return {
                status: StateStatus.LOADED,
                data: action.payload,
                entity: actionType.entity,
                version: +new Date(),
                errors: null,
            };

        case buildActionType(actionType, ActionStatus.FAILED):
            return {
                ...state,
                status: StateStatus.ERROR,
                errors: action.payload,
                data: initialData,
            };

        case buildActionType(actionType, ActionStatus.RESET):
            return { ...initialState, data: initialData };

        default:
            return { ...state };
    }
};

export { basicReducer };
