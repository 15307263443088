import { useDataTranslator } from 'hooks/useDataTranslator';
import { values } from 'lodash';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useRouting } from 'services/routing';
import { isServerSide } from 'services/utils';
import { Action, ActionType } from './local-actions';
import { filtersStateReducer } from './local-reducer';
import { FiltersState, initialState } from './local-state';

const FiltersContext = React.createContext<{ state?: FiltersState; dispatch?: React.Dispatch<Action> }>({});

export const FiltersContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(filtersStateReducer, {
        ...initialState,
    });
    const { queries } = useRouting();

    const filterSettings = useSelector((state: RootState) => state.settingsFilters.data);

    useEffect(() => {
        (({ cuisineId, occupationId, search, amenitiesIds, type }) => {
            // const tag = filterSettings?.tags?.find(t => t.id === tagId);
            dispatch({
                type: ActionType.SET_ALL_FILTERS,
                payload: {
                    type: type || null,
                    cuisineId: cuisineId || null,
                    occupationId: occupationId || null,
                    tagId: search ? decodeURIComponent(search) : null,
                    amenitiesIds: amenitiesIds?.split('%2C') || [],
                },
            });
        })(queries);
    }, [queries]);

    const contextValue = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};

export const FiltersConsumer = () => {
    return (
        <FiltersContext.Consumer>
            {values => {
                if (!isServerSide() && window._REACT_CONTEXT_DEVTOOL) {
                    window._REACT_CONTEXT_DEVTOOL({
                        id: 'filters_context_consumer',
                        displayName: 'Filters context consumer',
                        values,
                    });
                }
                return null;
            }}
        </FiltersContext.Consumer>
    );
};

const useState = () => {
    const { state } = useContext(FiltersContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(FiltersContext);
    return dispatch;
};

export const useFilters = () => {
    const state = useState();
    const dispatch = useDispatch();
    const { queries } = useRouting();

    const filterSettings = useSelector((state: RootState) => state.settingsFilters.data);
    const { translate } = useDataTranslator();

    return {
        typeFilter: state.type,
        occupationFilter: state.occupationId,
        tagFilter: state.tagId,
        cuisineFilter: state.cuisineId,
        amenitiesFilter: state.amenitiesIds,
        selectedFilters: state.selectedFilters,
        hasFilter: () => state.cuisineId || state.occupationId || state.tagId || state.amenitiesIds.length > 0,
        hasCuisineFilter: () => !!state.cuisineId,
        hasOccupationFilter: () => !!state.occupationId,
        hasAmenitiesFilter: () => state.amenitiesIds.length > 0,
        isAmenityFilterSelected: (amenity: string) => state.amenitiesIds.includes(amenity),
        setAllFilters: ({ cuisineId, occupationId, tagId, amenitiesIds, type }) => {
            dispatch({
                type: ActionType.SET_ALL_FILTERS,
                payload: {
                    cuisineId: cuisineId || null,
                    occupationId: occupationId || null,
                    tagId: tagId || null,
                    amenitiesIds: amenitiesIds || [],
                    type: type || null,
                },
            });
        },
        setSelectedFilters: ({ cuisineId, occupationId, tagId, amenitiesIds, type }) => {
            dispatch({
                type: ActionType.SET_SELECTED_FILTERS,
                payload: {
                    cuisineId: cuisineId || null,
                    occupationId: occupationId || null,
                    tagId: tagId || null,
                    amenitiesIds: amenitiesIds || [],
                    type: type || null,
                },
            });
        },
        setTypeFilter: type => {
            dispatch({
                type: ActionType.SET_TYPE,
                payload: {
                    type: type || null,
                },
            });
        },
        setCuisineFilter: cuisineId => {
            dispatch({
                type: ActionType.SET_CUISINE,
                payload: {
                    cuisineId: cuisineId || null,
                },
            });
        },
        setOccupationFilter: occupationId => {
            dispatch({
                type: ActionType.SET_OCCUPATION,
                payload: {
                    occupationId: occupationId || null,
                },
            });
        },
        setTagFilter: tagId => {
            dispatch({
                type: ActionType.SET_TAG,
                payload: {
                    tagId: tagId || null,
                },
            });
        },
        getNextSingleFilterValue: value => {
            if (value.selected) {
                return value.id;
            } else {
                return false;
            }
        },
        getNextTagValue: value => {
            if (value.selected) {
                return value.name.en;
            } else {
                return false;
            }
        },
        getNextAmenitiesFilterValue: value => {
            if (value.selected) {
                return [...state.selectedFilters.amenitiesIds, value.id];
            } else {
                return state.selectedFilters.amenitiesIds.filter(item => item !== value.id);
            }
        },
        getNextTypeFilter: type => {
            if (state.type === type) return false;
            else return type;
        },
        getNextTagFilter: tag => {
            if (decodeURIComponent(state.tagId) === tag.name.en) return false;
            else return tag.name.en;
        },
        getNextOccupationFilter: occupation => {
            if (state.occupationId === occupation.id) return false;
            else return occupation.id;
        },
        getNextCuisineFilter: cuisine => {
            if (state.cuisineId === cuisine.id) return false;
            else return cuisine.id;
        },
        getFiltersQuery: () => {
            const tag = filterSettings?.tags?.find(t => t.id === state.selectedFilters?.tagId);
            return {
                ...state.selectedFilters,
                amenitiesIds: state.selectedFilters.amenitiesIds.join(','),
                tagId: state.selectedFilters.tagId || null,
                search: translate(tag?.name),
            };
        },
        resetFilters: () => {
            const { type, cuisineId, occupationId, search, amenitiesIds } = queries;
            // const tag = filterSettings?.tags?.find(t => values(t?.name).includes(decodeURI(search)));
            dispatch({
                type: ActionType.SET_ALL_FILTERS,
                payload: {
                    type: type || null,
                    cuisineId: cuisineId || null,
                    occupationId: occupationId || null,
                    tagId: search || null,
                    amenitiesIds: amenitiesIds?.split('%2C') || [],
                },
            });
        },
    };
};
