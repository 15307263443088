import jwt from 'jsonwebtoken';

export const signToken = payload =>
    jwt.sign(payload, process.env.NEXT_PUBLIC_SECRET_KEY, {
        algorithm: 'HS256',
    });

export const _generateToken = async (id: string = '') => {
    const _token = signToken({ cartId: id });
    return _token;
};
