import axios from 'axios';
import { RequestAgentMethod } from 'types/rest-api';

const requestsLog = [];

const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const perform = async <T>(method: RequestAgentMethod, url: string, data: T, headers = undefined, isPublic = false) => {
    const performFunc = async () => {
        const _headers: any = headers
            ? {
                  'Content-Type': headers['Content-Type'] || 'application/json',
                  ...headers,
              }
            : {};

        if (!isPublic) _headers.Authorization = `Bearer ...`;

        const _axiosObject = { method, url, data, headers: _headers };
        if (!data) delete _axiosObject.data;

        return axios(_axiosObject);
    };

    try {
        const res = await performFunc();
        requestsLog.push({
            date: new Date().toUTCString(),
            urlRequested: url,
            requestType: method,
            requestData: data,
            wasSuccessful: true,
        });
        return res;
    } catch (e) {
        requestsLog.push({
            date: new Date().toUTCString(),
            urlRequested: url,
            requestType: method,
            requestData: data,
            wasSuccessful: false,
            error: e,
        });
        throw e;
    }
};

export const getRequestAgent = (version: string = '') => ({
    get: (path: string, isPublic: boolean = false, headers?: any) =>
        perform('GET', API_ENDPOINT + '/' + version + path, null, headers, isPublic),
    post: <T = {}>(path: string, data: T, isPublic = false, headers?: any) =>
        perform<T>('POST', API_ENDPOINT + '/' + version + path, data, headers, isPublic),
    delete: (path: string, isPublic: boolean = false, headers?: any) =>
        perform('DELETE', API_ENDPOINT + '/' + version + path, null, headers, isPublic),
    patch: <T = {}>(path: string, data: T, isPublic: boolean = false, headers?: any) =>
        perform<T>('PATCH', API_ENDPOINT + '/' + version + path, data, headers, isPublic),
    getLog: () => requestsLog,
    useUrl: url => {
        return {
            get: (isPublic: boolean = false, headers?: any) => perform('GET', url, null, headers, isPublic),
            post: <T = {}>(data: T, isPublic = false, headers?: any) => perform('POST', url, data, headers, isPublic),
            delete: (isPublic: boolean = false, headers?: any) => perform('DELETE', url, null, headers, isPublic),
            patch: <T = {}>(data: T, isPublic: boolean = false, headers?: any) =>
                perform<T>('PATCH', url, data, headers, isPublic),
        };
    },
});
