import { useFirestore } from 'hooks/useFirestore';
import React, { useReducer, useMemo, useContext, useEffect, useRef } from 'react';
import { authenticationStateReducer } from './local-reducer';
import { AuthState, initialState } from './local-state';
import firebase, { db, firebaseApp, firebaseAuth, secondaryAuth } from 'config/firebase';
import { currentUserFirestoreActions } from 'redux/actions/firestore/users';
import { Action, ActionType } from './local-actions';
import { useDispatch } from 'react-redux';
import { Subcollection } from 'types/firestore';
import Cookies from 'js-cookie';
import axios from 'axios';
import useLocalStorage from 'hooks/useLocalStorage';
import { Customer } from 'klikni-jadi-shared-stuff';
import { restExecutor } from 'services/rest-executor/executor';
import { _generateToken } from 'services/cart/useCartService/token';

const AuthContext = React.createContext<{ state?: AuthState; dispatch?: React.Dispatch<Action> }>({});

export const AuthContextProvider = ({ children, authenticated = false, uid = null }) => {
    const [state, dispatch] = useReducer(authenticationStateReducer, {
        ...initialState,
        isAuthenticated: authenticated,
        user: { id: uid },
    });
    const firestore = useFirestore('customers');
    const reduxDispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
            if (user) {
                // if(!authenticated &&)
                firestore.unsubscribe('currentUser');

                // if (user) {
                //     user.getIdToken(true).then(tokenResult => {
                //         if (!cartId || cartId === 'undefined') {
                //             console.log('tokenResult und', tokenResult);
                //             setCartId(tokenResult.claims.cartId);
                //         } else {
                //             setCartId(undefined);
                //             console.log('tokenResult', tokenResult);
                //             cartService.migrate(cartId, tokenResult.claims.cartId).then(() => {
                //                 setCartId(tokenResult.claims.cartId);
                //             });
                //         }
                //         Cookies.set('userCart', tokenResult.claims.cartId, { expires: 1000 });
                //     });
                // }

                db.doc(`/customers/${user.uid}`).onSnapshot(doc => {
                    if (doc.exists) {
                        db.doc(`/customers/${user.uid}/private_data/wallet`).onSnapshot(
                            walletDoc => {
                                if (walletDoc.exists) {
                                    reduxDispatch(
                                        currentUserFirestoreActions.success({
                                            id: doc.id,
                                            ...doc.data(),
                                            wallet: walletDoc.data(),
                                        } as Customer)
                                    );
                                } else {
                                    reduxDispatch(
                                        currentUserFirestoreActions.success({
                                            id: doc.id,
                                            ...doc.data(),
                                            wallet: null,
                                        } as Customer)
                                    );
                                }
                            },
                            e => {
                                console.log('User wallet snapshot error', e);
                            }
                        );
                    }
                });

                dispatch({
                    type: ActionType.AUTH_STATE_CHANGE,
                    payload: {
                        isAuthenticated: true,
                        user: {
                            id: user.uid,
                            email: user.email,
                            phone: user.phoneNumber,
                        },
                    },
                });
            } else {
                dispatch({
                    type: ActionType.AUTH_STATE_CHANGE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
                reduxDispatch(currentUserFirestoreActions.reset());
                // customersLogout.getMany();
                if (Cookies.get('__session')) {
                    axios.get('/api/signOut');
                }
            }
        });

        return unsubscribe;
    }, [reduxDispatch]);

    const contextValue = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const useState = () => {
    const { state } = useContext(AuthContext);
    return state;
};

export const useAuth = function () {
    const state = useState();
    const recaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier>(null);
    const [, setCartId] = useLocalStorage('_cart_id');

    const phoneVerification = {
        signInWithPhoneNumber: async (phone: string): Promise<firebase.auth.ConfirmationResult> => {
            return firebaseAuth.signInWithPhoneNumber(`+${phone}`, recaptchaVerifier.current).then(result => {
                window.confirmationResult = result;
                return Promise.resolve(result);
            });
        },
        linkWithPhoneNumber: async (phone: string) => {
            // const user = firebaseAuth.currentUser;
            const user = secondaryAuth.currentUser;
            if (user) {
                // user.getIdTokenResult().then(result => {
                //     const credential = firebase.auth.GoogleAuthProvider.credential(result.token);
                // });
                return user
                    .linkWithPhoneNumber(`+${phone}`, recaptchaVerifier.current)
                    .then((confirmationResult: firebase.auth.ConfirmationResult) => {
                        window.confirmationResult = confirmationResult;
                        return Promise.resolve(confirmationResult);
                    });
            }
            return Promise.reject({
                message: 'There is no user.',
            });
        },
    };

    return {
        ...state,
        checkPhone: async (phone: string) => {
            const executor = restExecutor('/customers');
            const _token = await _generateToken();
            return executor.post({ phoneNumber: phone }, { 'x-access-token': _token }, null, 'findByPhone');
        },
        getByEmail: async (email: string) => {
            const executor = restExecutor('/customers/findByEmail');
            const _token = await _generateToken();
            return executor.post({ email }, { 'x-access-token': _token });
        },
        signInWithEmailAndPassword: (email: string, password: string): Promise<firebase.auth.UserCredential> => {
            return firebaseAuth.signInWithEmailAndPassword(email, password);
        },
        signInWithGoogle: (loginHint?: string): Promise<firebase.auth.UserCredential> => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('email');
            if (loginHint) {
                provider.setCustomParameters({
                    login_hint: loginHint,
                });
            }

            return secondaryAuth.signInWithPopup(provider);
        },
        signInWithFacebook: (loginHint?: string): Promise<firebase.auth.UserCredential> => {
            const provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            if (loginHint) {
                provider.setCustomParameters({
                    login_hint: loginHint,
                });
            }

            // secondaryAuth.currentUser.

            return secondaryAuth.signInWithPopup(provider);
        },
        ...phoneVerification,
        verifyPhoneNumber: () => {
            if (!recaptchaVerifier.current) {
                const rf = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    size: 'invisible',
                });
                recaptchaVerifier.current = rf;
            }
            return { ...phoneVerification };
        },
        linkWithEmailCredential: (email: string, password: string): Promise<firebase.auth.UserCredential> => {
            return firebaseAuth.currentUser.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email, password));
        },
        confirmResult: (code: string): Promise<firebase.auth.UserCredential> => {
            if (window.confirmationResult) {
                return window.confirmationResult.confirm(code);
            }
            return Promise.reject({
                message: 'No confirmation result',
            });
        },
        getSignInMethodsForEmail: async (email: string) => {
            if (email) return await secondaryAuth.fetchSignInMethodsForEmail(email);
            return [];
        },
        logout: (): Promise<void> => {
            setCartId(undefined);
            Cookies.remove('userCart');
            return firebaseAuth.signOut();
        },
        logoutSecondary: () => {
            return secondaryAuth.signOut();
        },
        postUserToken: async (user: firebaseApp.User) => {
            if (user) {
                const token = await user.getIdToken();
                console.log('===== Token: =====');
                console.log(token);
                const path = `/api/auth`;
                const url = path;
                const data = { token: token };
                // return await customersAuth.post(data, {
                //     credentials: 'include',
                //     mode: 'same-origin',
                // });
                // Default options are marked with *
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                });
                return response.json(); // parses JSON response into native JavaScript objects
            }
        },
        loginWithCredential: (credential: firebase.auth.AuthCredential) => {
            return firebaseAuth.signInWithCredential(credential);
        },
        persistCredential: (credential: firebase.auth.AuthCredential) => {
            window.authCredential = credential;
        },
        loginWithPersistedCredential: (): Promise<firebase.auth.UserCredential> => {
            if (window.authCredential) {
                return firebaseAuth.signInWithCredential(window.authCredential);
            }
            return Promise.reject(null);
        },
    };
};

const customerSubcollections: Subcollection[] = [
    {
        path: 'private_data',
        storeAs: 'privateData',
        // subcollections: [
        //     {
        //         path: 'food_items',
        //         storeAs: 'items',
        //         collectionOptions: {
        //             queries: [
        //                 {
        //                     attribute: 'available',
        //                     operator: '==',
        //                     value: true,
        //                 },
        //             ],
        //         },
        //     },
        // ],
        collectionOptions: {
            queries: [
                {
                    attribute: firebase.firestore.FieldPath.documentId(),
                    operator: '==',
                    value: 'wallet',
                },
            ],
        },
    },
];
