import { Action } from 'core/design/Action';
import { ActionType } from 'redux/types/actionTypes';

export const USER_LOGIN_INFO_HANDLE: ActionType = {
    entity: 'USER_LOGIN_INFO',
    action: Action.HANDLE,
};
export const USER_INFO_GET: ActionType = { entity: 'USER_INFO', action: Action.GET };

export const CURRENT_USER_GET: ActionType = { entity: 'CURRENT_USER', action: Action.GET };
