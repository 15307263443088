import { createTheme } from '@mui/material/styles';
import typographyOverrides from './typography';
// import {MuiClock} from '@mui/lab/ClockPicker'

declare module '@mui/material/styles/components' {
    export interface MuiClockPickerToClassKey {}
}

export default createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiTab-root': {
                        outline: 'none',
                    },
                },
            },
            defaultProps: {
                TouchRippleProps: {
                    color: 'primary',
                },
                color: 'primary',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '&.landing-page-search-tabs': {
                        '& .MuiTab-root.Mui-selected': {
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '0px 2px 20px rgba(85, 141, 202, 0.15)',
                },
                root: {
                    zIndex: 9,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                paper: {
                    padding: '10px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.875em',
                    textTransform: 'none',
                    minWidth: 0,
                },
            },
            defaultProps: {
                color: 'primary',
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    // maxWidth: '1600px!important',
                },
                maxWidthMd: {
                    maxWidth: '1100px!important',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #a0a0a0',
                    color: '#555555',
                    borderRadius: '5px',
                    boxShadow: '0px 2px 10px 0px rgba(200,200,200,0.7)',
                },
                arrow: {
                    '&::before': {
                        color: '#f0f0f0',
                        border: '1px solid #a0a0a0',
                    },
                },
            },
            defaultProps: {
                arrow: true,
                PopperProps: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -5],
                            },
                        },
                    ],
                },
            },
        },

        ...typographyOverrides.components,
    },
});
