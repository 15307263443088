import { useRequestAgent } from './useRequestAgent';
import { handleError, handleSuccess } from '../services/rest-api/utils';
import { QueryParameters } from 'types/routing';
import { joinQueryParameters } from 'services/utils';
import { ExecutorResponse } from 'types/rest-api';
import { useEffect, useState } from 'react';

export const useExecutor = (_ENDPOINT_ROUTE: string, version: string = 'v1') => {
    const [ENDPOINT_ROUTE, SET_ENDPOINT_ROUTE] = useState<string>(_ENDPOINT_ROUTE);
    const requestAgent = useRequestAgent(version);

    useEffect(() => {
        SET_ENDPOINT_ROUTE(_ENDPOINT_ROUTE);
    }, [_ENDPOINT_ROUTE]);

    return {
        get: async (params?: QueryParameters, id?: string, headers?: any, property?: string) => {
            return await execute(
                requestAgent.get,
                `${ENDPOINT_ROUTE}${id ? `/${id}` : ''}${property ? `/${property}` : ''}${joinQueryParameters(params)}`,
                true,
                headers
            );
        },
        post: async <P = {}>(data: P, headers?: any, params?: QueryParameters, id?: string, action?: string) => {
            return await execute(
                requestAgent.post,
                `${ENDPOINT_ROUTE}${id ? `/${id}` : ''}${action ? `/${action}` : ''}${joinQueryParameters(params)}`,
                data,
                true,
                headers
            );
        },
        delete: async (id: string, headers?: any) => {
            return await execute(requestAgent.delete, `${ENDPOINT_ROUTE}/${id}`, true, headers);
        },
        patch: async <P = {}>(data: P, id: string, headers?: any) => {
            return await execute(requestAgent.patch, `${ENDPOINT_ROUTE}/${id}`, data, true, headers);
        },
    };
};

const execute = async (request, ...params): Promise<ExecutorResponse> => {
    try {
        const response = await request(...params);
        return handleSuccess(response);
    } catch (error) {
        if (error.response) {
            return handleError({ ...error.response, message: error.message });
        } else {
            return handleError({ status: 404, message: error.message || error });
        }
    }
};
