export interface AuthUser {
    id: string;
    email?: string;
    phone?: string;
}

export interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: AuthUser;
}

export const initialState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
};
