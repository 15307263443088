import { DialogInfo, DialogState, Globals, MapInfo, SignInProcessInfo, UsersLoginInfo } from './types/internalTypes';
import { RestaurantsState, SectionsState } from './types/reducerTypes';
import { Cart as UserCart } from 'klikni-jadi-shared-stuff';

export const SIGN_IN_PROCESS_DEFAULT: SignInProcessInfo = {
    previousSteps: [],
    currentStep: 'LOGIN_WITH_EMAIL',
};

export const USERS_LOGIN_INFO_DEFAULT: UsersLoginInfo = {
    name: '',
    lastName: '',
    phone: {
        countryCode: '',
        phoneNumber: '',
        fullNumber: '',
    },
    email: '',
    password: '',
    address: {
        fullAddress: '',
        latitude: 0,
        longitude: 0,
    },
};

export const dialogInfoDefault: DialogInfo = {
    alertVisible: false,
};

export const dialogsStateDefault: DialogState = {};

export const GLOBALS_DEFAULT: Globals = {
    isSearchFocused: false,
    listingsView: 'grid',
    myProfileOptionsShown: false,
    isLoginOpen: false,
    isRegisterOpen: false,
    isNotificationsOpen: false,
    isGalleryOpen: false,
    isCartPopupOpen: false,
};

export const MAP_INFO_DEFAULT: MapInfo = {
    openedProperties: [],
    isLoaded: false,
    loadError: null,
};

export const RESTAURANTS_DEFAULT: RestaurantsState = {
    data: [],
    metadata: {
        aggregations: {},
    },
    currentPage: 1,
    lastPage: false,
    total: 0,
};

export const SECTIONS_DEFAULT: SectionsState = {
    data: [],
    filters: {},
};

export const USER_CART_DEFAULT: UserCart = {
    type: 'delivery',
    isEmpty: true,
    isPreorder: false,
};
