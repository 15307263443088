import { useEffect, useRef } from 'react';
import { QueryDocumentSnapshot, DocumentData, DocumentSnapshot } from '@firebase/firestore-types';
import collectionApi from './collectionApi';
import docApi from './docApi';
import { GenericActions } from 'redux/types/actionTypes';
import { CollectionOptions, DocumentOptions } from 'types/firestore';
import { useDispatch } from 'react-redux';
import getQuery from 'config/firebase/getQuery';
import getFirestoreRef from 'config/firebase/getFirestoreRef';

export interface ListenerState {
    name?: string;
    unsubscribe: () => void;
}

export const useFirestore = <T extends DocumentData>(path: string) => {
    const collectionListenersRef = useRef<ListenerState[]>([]);
    const docListenersRef = useRef<ListenerState[]>([]);
    const lastDocRef = useRef<QueryDocumentSnapshot<DocumentData>>();

    useEffect(() => {
        return () => {
            collectionListenersRef.current?.forEach(listener => {
                listener.unsubscribe();
            });
            docListenersRef.current.forEach(listener => {
                listener.unsubscribe();
            });
        };
    }, [collectionListenersRef]);

    const dispatch = useDispatch();

    const collection = (actions: GenericActions<T>, options?: CollectionOptions) => {
        let query = getQuery(path, options);

        collectionApi<T>(query, actions, dispatch, collectionListenersRef, lastDocRef, options, path);

        if (options?.lazyLoad) {
            return {
                loadMore: (limit?: number) => {
                    limit && (query = getQuery(path, { ...options, limit }));
                    query = query.startAfter(lastDocRef.current);
                    collectionApi<T>(query, actions, dispatch, collectionListenersRef, lastDocRef, options, path);
                },
            };
        }
    };

    const docs = async (ids: string[], actions: GenericActions<T>) => {
        const docPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
        for (const id of ids) {
            docPromises.push(getFirestoreRef(path).doc(id).get());
        }
        dispatch(actions.loading());
        return Promise.all(docPromises)
            .then(docs => {
                const data = (docs.map(doc => doc.exists && { id: doc.id, ...doc.data() }) as unknown) as T;

                dispatch(actions.success(data));
            })
            .catch(err => {
                console.log(`Error getting docs from path: ${path}`, err);
                dispatch(actions.error(err.message));
            });
    };

    const doc = async (id: string, actions: GenericActions<T>, options?: DocumentOptions) => {
        docApi<T>(path, id, actions, dispatch, docListenersRef, options);
    };

    const id = () => {
        const ref = getFirestoreRef(path).doc();
        return ref.id;
    };

    const create = async (data: any) => {
        return getFirestoreRef(path)
            .add(data)
            .then(res => {
                console.log('Document created with id: ', res.id);
                return res.id;
            })
            .catch(e => console.log('Error creating document', e));
    };

    const update = async (id: string, data: any) => {
        return getFirestoreRef(path)
            .doc(id)
            .update(data)
            .then(() => console.log('Document updated.'))
            .catch(e => {
                console.log(`Error updating document with id: ${id}`, e);
                return Promise.reject(e);
            });
    };

    const set = async (id: string, data: any) => {
        return getFirestoreRef(path)
            .doc(id)
            .set(data, { merge: true })
            .then(() => console.log('Document updated.'))
            .catch(e => {
                console.log(`Error updating document with id: ${id}`, e);
                throw e;
            });
    };

    const remove = async (id: string) => {
        return getFirestoreRef(path)
            .doc(id)
            .delete()
            .then(() => console.log('Document deleted.'))
            .catch(e => {
                throw e;
            });
    };

    const unsubscribe = (listenerName?: string) => {
        if (listenerName) {
            collectionListenersRef.current.find(listener => listener.name === listenerName)?.unsubscribe();
            docListenersRef.current.find(listener => listener.name === listenerName)?.unsubscribe();
            return;
        }
        collectionListenersRef.current.forEach(listener => listener.unsubscribe());
        docListenersRef.current.forEach(listener => listener.unsubscribe());
    };

    return { collection, docs, doc, id, create, update, set, remove, unsubscribe };
};
