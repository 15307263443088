import { Restaurant } from 'klikni-jadi-shared-stuff';
import { ThunkAction } from 'redux-thunk';
import {
    RESTAURANTS_GET,
    RESTAURANT_GET,
    RESTAURANT_MENU_GET,
    FAVORITE_RESTAURANTS_GET,
    RECOMMENDED_RESTAURANTS_GET,
} from 'redux/constants/restaurants';
import { RootState } from 'redux/reducers';
import { PayloadAction } from '../../types/actionTypes';
import { buildActionType } from '../service/buildActionType';
import { createFirestoreActions } from '../service/actionBuilder';
import { ActionStatus } from 'core/design/ActionStatus';
import { downloadAndReplaceImages, downloadImages } from 'services/firestoreImages';
import { stateIsLoaded } from 'services/stateHelpers';
import { FoodMenu } from 'klikni-jadi-shared-stuff';

export const setAllRestaurants = (
    data: Restaurant[]
): ThunkAction<{}, RootState, {}, PayloadAction<Restaurant[]>> => async dispatch => {
    dispatch({
        type: buildActionType(RESTAURANTS_GET, ActionStatus.DONE),
        payload: data,
    });
};

export const restaurantsFirestoreActions = {
    all: createFirestoreActions<Restaurant[]>(
        RESTAURANTS_GET
        // async (state, data) => {
        //     let d = data;
        //     if (!stateIsLoaded(state.restaurants))
        //         d = await downloadImages<Restaurant>(data, ['images.thumbnailRef']);
        //     else {
        //         d = await downloadAndReplaceImages<Restaurant>(state.restaurants.data.results, data, [
        //             'images.thumbnailRef',
        //         ]);
        //     }
        //     return d;
        // }
    ),
    single: createFirestoreActions<Restaurant>(
        RESTAURANTS_GET
        // async (state, data) => {
        //     let d = data;
        //     if (!stateIsLoaded(state.restaurants))
        //         d = await downloadImages<Restaurant>(data, ['images.thumbnailRef']);
        //     else {
        //         d = await downloadAndReplaceImages<Restaurant>(state.restaurants.data.results, data, [
        //             'images.thumbnailRef',
        //         ]);
        //     }
        //     return d;
        // }
    ),
};

export const restaurantDetailsFirestoreActions = createFirestoreActions<Restaurant>(RESTAURANT_GET);

export const restaurantMenusFirestoreActions = createFirestoreActions<FoodMenu[]>(RESTAURANT_MENU_GET);

export const favoriteRestaurantsFirestoreActions = createFirestoreActions<Restaurant[]>(
    FAVORITE_RESTAURANTS_GET,
    async (state, data) => {
        let d = data;
        // if (!stateIsLoaded(state.favoriteRestaurants)) d = await downloadImages<Restaurant>(data, ['images.thumbnailRef']);
        // else {
        //     d = await downloadAndReplaceImages<Restaurant>(state.favoriteRestaurants.data, data, ['images.thumbnailRef']);
        // }
        return d;
    }
);

export const recommendedRestaurantsFirestoreActions = createFirestoreActions<Restaurant[]>(
    RECOMMENDED_RESTAURANTS_GET,
    async (state, data) => {
        let d = data;
        // if (!stateIsLoaded(state.recommendedRestaurants)) d = await downloadImages<Restaurant>(data, ['images.thumbnailRef']);
        // else {
        //     d = await downloadAndReplaceImages<Restaurant>(state.recommendedRestaurants.data, data, ['images.thumbnailRef']);
        // }
        return d;
    }
);
