import { Cart, Restaurant } from 'klikni-jadi-shared-stuff';
import { restaurantsFirestoreActions } from 'redux/actions/firestore/restaurants';
import {
    CARTS,
    CUSTOMERS_AUTH,
    CUSTOMERS_CLAIMS,
    CUSTOMERS_COOKIE,
    CUSTOMERS_LOGOUT,
    PROPERTIES_AVAILABILITY,
    PROPERTIES_SEARCH,
} from 'services/rest-api/apiRoutes';
import { useRestDispatcher } from './useRestDispatcher';

export const useRest = () => {
    const restaurantsDispatcher = useRestDispatcher<Restaurant>(restaurantsFirestoreActions, PROPERTIES_SEARCH);
    const cartsDispatcher = useRestDispatcher<Cart>(null, CARTS);
    const migrateCartDispatcher = useRestDispatcher<{ cartFrom: string; cartTo: string }>(null, CARTS);
    const createCartDispatcher = useRestDispatcher<Cart>(null, CARTS);
    const customersAuthDispatcher = useRestDispatcher<any>(null, CUSTOMERS_AUTH);
    const customersLogoutDispatcher = useRestDispatcher<any>(null, CUSTOMERS_LOGOUT);
    const customersCookieDispatcher = useRestDispatcher<any>(null, CUSTOMERS_COOKIE);
    const customersClaimsDispatcher = useRestDispatcher<any>(null, CUSTOMERS_CLAIMS);
    const propertiesAvailability = useRestDispatcher<any>(null, PROPERTIES_AVAILABILITY);
    return {
        restaurants: restaurantsDispatcher.methods,
        carts: cartsDispatcher.methods,
        migrateCart: migrateCartDispatcher.methods,
        createCart: createCartDispatcher.methods,
        customersAuth: customersAuthDispatcher.methods,
        customersLogout: customersLogoutDispatcher.methods,
        customersCookie: customersCookieDispatcher.methods,
        customerClaims: customersClaimsDispatcher.methods,
        propertiesAvailability: propertiesAvailability.methods,
    };
};
