import { createGenericContext } from './generic-context';

export interface DialogInfo {
    alertVisible: boolean;
    contentText?: string;
    cancelText?: string;
    successText?: string;
}

export interface FnCallback {
    (): void;
}

const GenericContext = createGenericContext<{
    dialogData: DialogInfo;
    onSuccess?: FnCallback;
    onCancel?: FnCallback;
}>({
    dialogData: {
        alertVisible: false,
    },
});

export const DialogProvider = GenericContext.Provider;

export const useDialogContext = GenericContext.useGenericContext;
