module.exports = {
    locales: ['mk', 'en'],
    defaultLocale: 'mk',
    pages: {
        '*': ['common'],
    },
    interpolation: {
        prefix: '{{',
        suffix: '}}',
        format: function (value, format) {
            if (format === 'uppercase') return value.toUpperCase();
            if (format === 'lowercase') return value.toLowerCase();
            if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
            return value;
        },
    },
};
