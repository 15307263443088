import { ValidationErrorObject, ValidationInfo } from 'redux/types/internalTypes';

export enum validationTypes {
    PHONE_VERIFICATION,
    CODE_CONFIRMATION,
    EMAIL_LOGIN,
    EMAIL_PASSWORD_LOGIN,
    LOGIN_BACKEND_ERROR,
    REGISTER_INFO,
    REGISTER_INFO_EMAIL_PASSWORD,
    CHANGE_PASSWORD,
    BECOME_PARTNER,
}

export type ValidationType = keyof typeof validationTypes;

export const VALIDATION_ERROR_OBJECT_DEFAULT: ValidationErrorObject = {
    hasError: false,
    message: '',
    errorType: '',
};

export const VALIDATION_INFO_DEFAULT: ValidationInfo = Object.keys(validationTypes).reduce(
    (acc, item) =>
        (isNaN(Number(item)) && {
            ...acc,
            [item]: VALIDATION_ERROR_OBJECT_DEFAULT,
        }) ||
        acc,
    {} as ValidationInfo
);
