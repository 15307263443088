import { Palette } from '@mui/material/styles';

export const palette: Partial<Palette> = {
    primary: {
        main: '#009de0',
        light: '#44B8FD',
        contrastText: '',
        dark: '',
    },
    secondary: {
        // main: '#2CA8B2', //blue/green
        // light: '#A8D0DA',
        // main: '#D8042A', //red
        // light: '#FE8C67',
        // main: '#db7093', //pink
        // light: '#db7093',
        main: '#E7F2F8', //contrasted
        light: '#FFFAF7',
        contrastText: '',
        dark: '',
    },
    grey: {
        '50': '#f6f6f6',
        '100': '#f2f2f2',
        '200': '#EDEDED',
        '300': '#E9E9E9',
        '400': '#E5E5E5',
        '500': '#E1E1E1',
        '600': '#CCCCCC',
        '700': '#B8B8B8',
        '800': '#A3A3A3',
        '900': '#8F8F8F',
        A100: '#777777',
        A200: '#d8d8d8',
        A400: '#d8d8d8',
        A700: '#d8d8d8',
    },
};
