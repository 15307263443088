export interface FiltersState {
    cuisineId: string;
    occupationId: string;
    tagId: string;
    amenitiesIds: string[];
    type: string;
    selectedFilters: {
        cuisineId: string;
        occupationId: string;
        tagId: string;
        amenitiesIds: string[];
        type: string;
    };
}

export const initialState: FiltersState = {
    cuisineId: null,
    occupationId: null,
    tagId: null,
    amenitiesIds: [],
    type: null,
    selectedFilters: {
        cuisineId: null,
        occupationId: null,
        tagId: null,
        amenitiesIds: [],
        type: null,
    },
};
