import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { GenericActions } from 'redux/types/actionTypes';
import { ExecutorResponse } from 'types/rest-api';
import { QueryParameters } from 'types/routing';
import { useExecutor } from './useExecutor';

export type ActionMutator<P = {}> = (data: P) => P;
type AnyMutator = ActionMutator<{}[]> & ActionMutator<{}> & ActionMutator<Partial<{}>>;
export type MutatorMethod = 'get' | 'post' | 'patch';
interface Mutators<P = {}> {
    get: ActionMutator<P[]>;
    post: ActionMutator<P>;
    patch: ActionMutator<Partial<P>>;
}

interface Actions<P> {
    all: GenericActions<P[]>;
    single: GenericActions<P>;
}

export const useRestDispatcher = <P extends {}>(actions: Actions<P>, api: string) => {
    const dispatch = useDispatch();
    const executor = useExecutor(api);

    const mutators = useRef<Mutators<P>>({
        get: null,
        post: null,
        patch: null,
    });

    useEffect(() => {
        return () => {
            if (actions) {
                // dispatch(actions.all.reset());
                // dispatch(actions.single.reset());
            }
        };
    }, [actions]);

    const dispatchResponse = (response: ExecutorResponse, currentActions: GenericActions<P | P[]>, mutator?: AnyMutator) => {
        if (response.success) {
            const data = mutator ? mutator(response.data) : response.data;
            dispatch(currentActions.success(data));
        } else {
            dispatch(currentActions.error(response.message));
        }
    };

    return {
        addMutator: (method: MutatorMethod, mutator) => {
            mutators.current[method] = mutator;
        },
        methods: {
            getMany: async (params?: QueryParameters, headers?: any) => {
                if (actions) dispatch(actions.all.loading());
                const response = await executor.get(params, null, headers);
                if (actions) dispatchResponse(response, actions.all, mutators.current.get);
                return response;
            },
            getOne: async (id: string, params?: QueryParameters, headers?: any, property?: string) => {
                if (actions) dispatch(actions.single.loading());
                const response = await executor.get(params, id, headers, property);
                if (actions) dispatchResponse(response, actions.single, mutators.current.get);
                return response;
            },
            post: async (data: P, headers?: any, params?: QueryParameters, id?: string, action?: string) => {
                const _data = mutators.current.post ? mutators.current.post(data) : data;
                return await executor.post<P>(_data as P, headers, params, id, action);
            },
            delete: async (id: string, params?: QueryParameters, headers?: any) => {
                await executor.delete(id, headers);
                const response = await executor.get(params, null, headers);
                if (actions) dispatchResponse(response, actions.all, mutators.current.get);
                return response;
            },
            patch: async (data: Partial<P>, id: string, headers?: any) => {
                const _data = mutators.current.patch ? mutators.current.patch(data) : data;
                return await executor.patch<Partial<P>>(_data, id, headers);
            },
        },
    };
};

export interface RestDispatcherMethods {
    getMany: (params?: QueryParameters, headers?: any) => Promise<ExecutorResponse>;
    getOne: (id: string, params?: QueryParameters, headers?: any, property?: string) => Promise<ExecutorResponse>;
    post: (data: any, headers?: any, params?: QueryParameters, id?: string, action?: string) => Promise<ExecutorResponse>;
    delete: (id: string, params?: QueryParameters, headers?: any) => Promise<ExecutorResponse>;
    patch: (data: Partial<any>, id: string, headers?: any) => Promise<ExecutorResponse>;
}
