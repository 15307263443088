import { Action } from 'core/design/Action';
import { ActionType } from 'redux/types/actionTypes';

export const CART_ITEMS_HANDLE: ActionType = {
    entity: 'CART_ITEMS',
    action: Action.HANDLE,
};

export const GROUP_CART_ITEMS_HANDLE: ActionType = {
    entity: 'CART_ITEMS',
    action: Action.HANDLE,
};

export const USER_CART_HANDLE: ActionType = {
    entity: 'USER_CART',
    action: Action.HANDLE,
};
