import { StateStatus } from 'core/design/StateStatus';

const stateHasItems = obj => obj && obj === StateStatus.LOADED && obj.data && obj.data.length > 0;

const stateIsEmpty = obj =>
    obj && obj === StateStatus.LOADED && (!obj.data || obj.data.length === 0);

const stateIsLoading = obj => !obj || obj.status === StateStatus.LOADING;

const stateIsNotInitialized = obj => !obj || obj.status === StateStatus.NOT_INITIALIZED;

const stateIsNotReady = obj => !obj || obj.status <= StateStatus.LOADING;

const stateIsLoaded = obj => obj.status === StateStatus.LOADED;

const stateHasFailed = obj => obj.status === StateStatus.ERROR;

// const statesAreLoaded = states => states && states.every(x => x.loaded);

// const statesAreLoading = states => !states || states.some(x => x.loading);

// const statesAreNotInitialized = states => !states || states.some(x => !x.data);

// export { stateHasItems, stateIsEmpty, stateIsLoading, stateIsNotInitialized, statesAreLoaded, statesAreLoading, statesAreNotInitialized };

export {
    stateHasItems,
    stateIsEmpty,
    stateIsLoading,
    stateIsNotInitialized,
    stateIsNotReady,
    stateIsLoaded,
    stateHasFailed,
};
