import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import themeOverrides from './overrides';

export default createTheme({
    palette,
    spacing: 8,
    components: themeOverrides.components,
    typography: {
        fontFamily: 'Futura_Round, SF_Pro',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});
