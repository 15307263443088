export enum ActionType {
    SET_ALL_FILTERS = 'set_all_filters',
    SET_SELECTED_FILTERS = 'set_selected_filters',
    SET_CUISINE = 'set_cuisine',
    SET_OCCUPATION = 'set_occupation',
    SET_TAG = 'set_tag',
    SET_TYPE = 'set_type',
}

export type Action = {
    type: ActionType;
    payload: {
        cuisineId?: string;
        occupationId?: string;
        tagId?: string;
        amenitiesIds?: string[];
        type?: string;
    };
};
