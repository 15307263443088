import { Action } from 'core/design/Action';
import { ActionType } from 'redux/types/actionTypes';

export const RESTAURANTS_GET: ActionType = { entity: 'RESTAURANTS', action: Action.GET_MANY };

export const RESTAURANT_GET: ActionType = { entity: 'RESTAURANT', action: Action.GET };

export const RESTAURANT_MENU_GET: ActionType = { entity: 'RESTAURANT_MENU', action: Action.GET };

export const RECOMMENDED_RESTAURANTS_GET: ActionType = {
    entity: 'RECOMMENDED_RESTAURANTS',
    action: Action.GET_MANY,
};

export const FAVORITE_RESTAURANTS_GET: ActionType = {
    entity: 'FAVORITE_RESTAURANTS',
    action: Action.GET_MANY,
};

export const PROMOTED_RESTAURANTS_GET: ActionType = {
    entity: 'PROMOTED_RESTAURANTS',
    action: Action.GET_MANY,
};
