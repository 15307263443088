import { Cart } from 'klikni-jadi-shared-stuff';
import _ from 'lodash';
import { USER_CART_HANDLE } from 'redux/constants/cart';
import { countInNestedObject } from 'services/utils';
import { createFirestoreActions } from '../service/actionBuilder';

export const cartFirestoreActions = createFirestoreActions<Cart>(USER_CART_HANDLE, async (state, data) => {
    data.isEmpty = _.isEmpty(data.groupedItems);
    data.count = countInNestedObject(data.groupedItems, 'items');
    return data;
});
