import { Cart } from 'klikni-jadi-shared-stuff';
import { RestDispatcherMethods } from 'hooks/useRestDispatcher';
import { _generateToken } from './token';

export interface CartServicePrepareConfig {
    prepareFor: string;
    currentCart: Cart;
    cartId: string;
    api: RestDispatcherMethods;
}

type PostEffectCallback = () => any;
type PatchEffectCallback = (cart: Cart) => any;

export const preparePost = async (config: CartServicePrepareConfig, id?: string, action?: string) => {
    const _token = await _generateToken(config.currentCart.id);
    return async (cb: PostEffectCallback) => {
        const _payload = cb();
        const payload = {
            ..._payload,
        };

        const res = await config.api.post(payload, { 'x-access-token': _token }, null, id, action);
        return res;
    };
};

export const preparePatch = async (config: CartServicePrepareConfig) => {
    const _token = await _generateToken(config.cartId);
    const _id = config.cartId;
    config.currentCart.groupedItems = config.currentCart.groupedItems || {};
    return (cb: PatchEffectCallback) => {
        const _payload = cb(config.currentCart);
        const payload = {
            ..._payload,
        };

        return config.api.patch(payload, _id, { 'x-access-token': _token });
    };
};
