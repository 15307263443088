export enum ActionType {
    HEADER_STATE_CHANGE = 'HEADER_STATE_CHANGED',
}

export type Action = {
    type: ActionType;
    payload: {
        headerPinned: boolean;
    };
};
