import { Box, CircularProgress, Paper } from '@mui/material';

interface IGlobalLoadingProps {
    showGlobalLoader: boolean;
}

const GlobalLoading = ({ showGlobalLoader }: IGlobalLoadingProps) => {
    return (
        <Box
            position="fixed"
            top={0}
            bottom={0}
            left={0}
            right={0}
            height="100vh"
            sx={{
                backgroundColor: '#efefef',
                opacity: showGlobalLoader ? 1 : 0,
                transition: 'opacity 100ms ease-in-out',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={99999}
        >
            <Paper
                sx={{
                    p: 6,
                    borderRadius: 3,
                }}
            >
                <CircularProgress size={40} />
            </Paper>
        </Box>
    );
};

export default GlobalLoading;
