import React, { useReducer, useMemo, useContext } from 'react';
import { HeadroomState, initialState } from './local-state';
import { Action, ActionType } from './local-actions';
import { headroomStateReducer } from './local-reducer';

const HeadroomContext = React.createContext<{ state?: HeadroomState; dispatch?: React.Dispatch<Action> }>({});

export const HeadroomContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(headroomStateReducer, {
        ...initialState,
    });

    const contextValue = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return <HeadroomContext.Provider value={contextValue}>{children}</HeadroomContext.Provider>;
};

const useState = () => {
    const { state } = useContext(HeadroomContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(HeadroomContext);
    return dispatch;
};

export const useHeadroom = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        headerPinned: state.headerPinned,
        pinHeader: () => {
            dispatch({ type: ActionType.HEADER_STATE_CHANGE, payload: { headerPinned: true } });
        },
        unpinHeader: () => {
            dispatch({ type: ActionType.HEADER_STATE_CHANGE, payload: { headerPinned: false } });
        },
    };
};
