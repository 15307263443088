import { createGenericContext } from './generic-context';

export type LocationType = 'address' | 'city' | 'other';
export interface LocationState {
    location: {
        longitude: number;
        latitude: number;
        fullAddress: string;
    };
    region: string;
    addressId?: string;
    locationType?: LocationType;
}

const GenericContext = createGenericContext<LocationState>({
    location: null,
    region: null,
});

export const LocationProvider = GenericContext.Provider;

export const useLocationContext = GenericContext.useGenericContext;
