import { LocaleStrings } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';

export const useDataTranslator = () => {
    const { lang } = useTranslation('common');
    return {
        translate: (data: LocaleStrings) => {
            if (data)
                if (data[lang] && data[lang] !== '') {
                    return data[lang];
                } else {
                    if (data['en']) {
                        return data['en'];
                    } else {
                        return data['mk'];
                    }
                }
        },
    };
};
