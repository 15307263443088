import { MutableRefObject } from 'react';
import { DocumentData, DocumentSnapshot } from '@firebase/firestore-types';
import { ListenerState } from './index';
import { GenericActions } from 'redux/types/actionTypes';
import { DocumentOptions, FirestoreErrors, Subcollection } from 'types/firestore';
import { getFirestoreRef } from 'services/db/firestoreApi';
import getQuery from 'config/firebase/getQuery';

const docApi = async <T>(
    path: string,
    id: string,
    actions: GenericActions<T>,
    dispatch,
    docListenersRef?: MutableRefObject<ListenerState[]>,
    options?: DocumentOptions,
    ssr: boolean = false
) => {
    const docRef = getFirestoreRef(path).doc(id);

    if (!ssr) dispatch(actions?.loading());
    if (options?.listen && docListenersRef) {
        const listener = docRef.onSnapshot(doc => {
            if (!doc.exists) {
                dispatch(
                    actions.error({
                        code: FirestoreErrors.DocumentDoesNotExist,
                        message: `Document with id: ${id} does not exist.`,
                    })
                );
                return;
            }

            dispatch(actions.success({ id: doc.id, ...doc.data() } as unknown as T));
        });
        docListenersRef.current.push({ name: options.listenerName, unsubscribe: listener });
    } else {
        try {
            const doc = await docRef.get();

            if (!doc.exists) {
                await dispatch(actions.error('Document does not exists.'));
                throw doc;
            }

            let result: DocumentData = { id: doc.id, ...doc.data() };
            await dispatch(actions.success(result as T));

            const subcollections = await getSubcollectionsForDocument(doc, options?.subcollections);
            result = { ...result, ...subcollections };

            await dispatch(actions.success(result as T));
        } catch (err) {
            console.error('get document error', err);
            dispatch(actions.error(err.message));
        }
    }
};

export const getSubcollectionsForDocument = async (document: DocumentSnapshot<DocumentData>, subcollections: Subcollection[]) => {
    if (!subcollections) return {};
    const subs = {};
    for (const subcoll of subcollections) {
        const snapQuery = getQuery({ ref: document.ref.collection(subcoll.path) }, subcoll.collectionOptions);
        const snap = await snapQuery.get();
        const innerDocs = [];
        if (!snap.empty || snap.docs.length) {
            for (let index = 0; index < snap.docs.length; index++) {
                const doc = snap.docs[index];
                innerDocs.push({
                    id: doc.id,
                    ...doc.data(),
                    ...(await getSubcollectionsForDocument(doc, subcoll.subcollections)),
                });
            }
        }
        subs[subcoll.storeAs] = innerDocs;
    }
    return subs;
};

export default docApi;
