import useLocalStorage from 'hooks/useLocalStorage';
import { useRest } from 'hooks/useRest';
import { Cart, CartItem, CustomerAddress, Payment, PaymentType, Restaurant } from 'klikni-jadi-shared-stuff';
import { min, sum, values } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartFirestoreActions } from 'redux/actions/firestore/cart';
import { RootState } from 'redux/reducers';
import { stateIsLoaded, stateIsLoading } from 'services/stateHelpers';
import { useCartLoadingState } from '../CartServiceProvider';
import { CartServicePrepareConfig, preparePatch, preparePost } from './prepare';

interface PrepareOptions {
    priceChanging?: boolean;
    deliveryChanging?: boolean;
    typeChanging?: boolean;
}

export const useCartService = () => {
    const {
        loading,
        loadingPrice,
        loadingDelivery,
        loadingType,
        setLoading,
        setLoadingPrice,
        setLoadingDelivery,
        setLoadingType,
    } = useCartLoadingState();

    const cart = useSelector((state: RootState) => state.userCart);
    const [cartId, setCartId] = useLocalStorage('_cart_id');
    const cartRef = useRef<string>(cartId);
    const dispatch = useDispatch();

    const { carts, migrateCart, createCart } = useRest();

    useEffect(() => {
        cartRef.current = cartId;
    }, [cartId]);

    useEffect(() => {
        if (!stateIsLoading(cart)) {
            setTimeout(() => {
                setLoading(false);
                setLoadingPrice(false);
                setLoadingDelivery(false);
                setLoadingType(false);
            }, 500);
        }
    }, [cart.status]);

    const count = useMemo(() => {
        return sum(values(cart.data?.groupedItems).map(item => sum(values(item.items).map(i => i.quantity))));
    }, [cart.data?.groupedItems]);

    const _prepare = async (prepareType: RequestType, options: PrepareOptions = {}) => {
        const { priceChanging = true, deliveryChanging = false, typeChanging = false } = options;

        dispatch(cartFirestoreActions.loading());

        console.log(`====== Type of request action: ${prepareType} ======`);
        setLoading(true);
        if (priceChanging) setLoadingPrice(true);
        if (deliveryChanging) setLoadingDelivery(true);
        if (typeChanging) setLoadingType(true);

        const config: CartServicePrepareConfig = {
            currentCart: cart.data,
            cartId: cartId,
            prepareFor: prepareType,
            api: null,
        };

        let callbackPromise: (cb: EffectCallback) => Promise<any>;

        try {
            switch (prepareType) {
                case 'items':
                case 'priority':
                case 'wallet':
                case 'tip':
                case 'coupon':
                case 'address':
                case 'preorder':
                case 'customerChange':
                case 'roundup':
                case 'payment':
                case 'driverComment':
                case 'type':
                    config.api = carts;
                    callbackPromise = await preparePatch(config);
                    break;
                case 'create':
                    config.api = createCart;
                    callbackPromise = await preparePost(config);
                    break;
                case 'migrate':
                    config.api = migrateCart;
                    callbackPromise = await preparePost(config, cartId, 'migrate');
                    break;
                default:
                    throw Error('Unknown request type');
            }

            return [callbackPromise, priceChanging, deliveryChanging, typeChanging];
        } catch (e) {
            dispatch(cartFirestoreActions.done());
            return [() => {}];
        }
    };

    const getUsedWalletMoney = () => {
        const { wallet } = cart.data;
        let usedWalletMoney = 0;
        const _total = getTotalCartPrice();
        if (wallet && wallet > 0) {
            usedWalletMoney = min([wallet, _total]);
        }
        return +Number(usedWalletMoney).toFixed(2);
    };

    const getTotalCartPrice = (online: boolean = false) => {
        const { itemsTotalCost, deliveryFee, tip, roundup, priorityFee, type, serviceFee, onlineFee } = cart.data;
        return +Number(
            itemsTotalCost +
                (priorityFee || 0) +
                ((type !== 'takeout' && deliveryFee) || 0) +
                (tip || 0) +
                (roundup || 0) +
                (serviceFee || 0) +
                (online ? onlineFee || 0 : 0)
        ).toFixed(2);
    };

    const effect =
        (cb: EffectCallback) =>
        ([eff, priceChanging, deliveryChanging, typeChanging]: [
            (cb: EffectCallback) => Promise<any>,
            boolean,
            boolean,
            boolean
        ]) => {
            const res = eff(cb).then(_res => {
                dispatch(cartFirestoreActions.done());
                return _res;
            });

            return res;
        };

    return {
        cartId,
        isEmpty: cart.data?.isEmpty,
        count,
        loading,
        loadingPrice,
        loadingDelivery,
        loadingType,
        setLoading: () => {
            setLoading(true);
        },
        // addItem: async (item: Partial<CartItem>, restaurant: Partial<Restaurant>, type?: string) =>
        //     await _prepare('items', { deliveryChanging: true }).then(
        //         effect(() => ({
        //             update: 'items',
        //             data: {
        //                 restaurantId: restaurant.id,
        //                 foodMenuId: restaurant?.activeFoodMenuId,
        //                 foodItemId: item?.id,
        //                 quantity: item.quantity,
        //                 selectedPortionId: item.selectedPortionId,
        //                 excluded: item.excluded,
        //                 comment: item.comment,
        //                 extras: item.extras,
        //                 categoryId: item.categoryId,
        //                 partnerCode: restaurant.partnerCode,
        //             },
        //         }))
        //     ),
        addItems: async (items: Partial<CartItem>[], restaurant: Partial<Restaurant>, type?: string) =>
            await _prepare('items', { deliveryChanging: true }).then(
                effect(() => ({
                    update: 'items',
                    data: {
                        items: items.map(item => ({
                            restaurantId: restaurant.id,
                            foodMenuId: restaurant?.activeFoodMenuId,
                            foodItemId: item?.id,
                            quantity: item.quantity,
                            selectedPortionId: item.selectedPortionId,
                            excluded: item.excluded,
                            comment: item.comment,
                            extras: item.extras,
                            categoryId: item.categoryId,
                            partnerCode: restaurant.partnerCode,
                        })),
                    },
                }))
            ),
        removeItem: async (restaurantId: string, itemId: string) =>
            await _prepare('items').then(
                effect(() => ({
                    update: 'items',
                    data: {
                        items: [
                            {
                                restaurantId,
                                cartItemId: itemId,
                                quantity: 0,
                            },
                        ],
                    },
                }))
            ),
        changeQuantity: async (restaurantId: string, itemId: string, quantity: number) =>
            await _prepare('items').then(
                effect(() => ({
                    update: 'items',
                    data: {
                        items: [
                            {
                                restaurantId,
                                cartItemId: itemId,
                                quantity,
                            },
                        ],
                    },
                }))
            ),
        addComment: async (restaurantId: string, itemId: string, comment: string) =>
            await _prepare('items', { priceChanging: false }).then(
                effect(() => ({
                    update: 'items',
                    data: {
                        items: [
                            {
                                restaurantId,
                                cartItemId: itemId,
                                comment,
                            },
                        ],
                    },
                }))
            ),
        // addHost: async (host: OrderCustomer) => await _prepare('items').then(effect(_cart => ((_cart.host = host), _cart))),
        addAddressToHost: async (address: CustomerAddress) =>
            await _prepare('address', { deliveryChanging: true }).then(
                effect(_cart => ({ update: 'address', data: { customerId: _cart.host?.id, addressId: address.id } }))
            ),
        addPriority: async (priority: boolean, region: string) =>
            await _prepare('priority').then(
                effect(() => ({ update: 'priority', data: { isPriority: priority, region: region } }))
            ),
        addPreorder: async (date: Date, isPreorder: boolean) =>
            await _prepare('preorder').then(
                effect(() => ({
                    update: 'preorder',
                    data: {
                        deliveryDate: date || new Date(),
                        isPreorder,
                    },
                }))
            ),
        addPayment: async (payment: Payment) =>
            await _prepare('payment').then(
                effect(() => ({
                    update: 'payment',
                    data: {
                        payment,
                    },
                }))
            ),
        addDriverComment: async (driverComment: string) =>
            await _prepare('driverComment').then(
                effect(() => ({
                    update: 'driverComment',
                    data: {
                        driverComment,
                    },
                }))
            ),
        addCustomerChange: async (customerChange: number) =>
            await _prepare('customerChange').then(
                effect(() => ({
                    update: 'customerChange',
                    data: {
                        customerChange,
                    },
                }))
            ),
        addWallet: async (wallet: boolean) =>
            await _prepare('wallet').then(effect(_cart => ({ update: 'wallet', data: { customerId: _cart.host?.id, wallet } }))),
        addTip: async (tip: number) => await _prepare('tip').then(effect(() => ({ update: 'tip', data: { tip } }))),
        addCoupon: async (coupon: { couponCode: string; couponId: string }) =>
            await _prepare('coupon').then(effect(() => ({ update: 'coupon', data: { coupon } }))),
        addRoundup: async (roundup: boolean, restaurantId: string) =>
            await _prepare('roundup').then(effect(() => ({ update: 'roundup', data: { restaurantId, roundup } }))),
        changeType: async (type: string) =>
            await _prepare('type', { typeChanging: true }).then(
                effect(() => ({ update: 'orderType', data: { orderType: type } }))
            ),
        migrate: async (toCart: string) => {
            return _prepare('migrate').then(
                effect(() => ({
                    toCartId: toCart,
                }))
            );
        },

        createCart: async () => await _prepare('create').then(effect(() => ({ type: 'delivery' }))),
        getFinalCartPrice: (online: boolean = false) => {
            if (stateIsLoaded(cart)) {
                return getTotalCartPrice(online) - getUsedWalletMoney() - (cart.data.takeoutDiscount || 0);
            }
            return 0;
        },
        getTotalCartPrice,
        getUsedWalletMoney,
    };
};

type EffectCallback = (_cart?: Cart) => any;
type RequestType =
    | 'items'
    | 'address'
    | 'preorder'
    | 'priority'
    | 'wallet'
    | 'coupon'
    | 'tip'
    | 'migrate'
    | 'create'
    | 'roundup'
    | 'type'
    | 'customerChange'
    | 'payment'
    | 'driverComment';
