import {
    SCROLL_HEADER_SEARCH_ZONE,
    SCROLL_HEADER_TRANSPARENT_ZONE,
    SCROLL_NO_HEADER_SEARCH_ZONE,
    SCROLL_NO_HEADER_TRANSPARENT_ZONE,
} from '../../constants/scroller';
import { StateStatus } from 'core/design/StateStatus';
import { ActionStatus } from 'core/design/ActionStatus';
import { buildActionType } from '../../actions/service/buildActionType';
import { AnyAction } from 'redux';

export interface ScrollerState {
    status: number;
    isSearchInHeader: boolean;
    isHeaderTransparent: boolean;
}

const scroller = (
    state = {
        status: StateStatus.LOADED,
        isSearchInHeader: false,
        isHeaderTransparent: true,
    },
    action: AnyAction
): ScrollerState => {
    switch (action.type) {
        case buildActionType(SCROLL_HEADER_SEARCH_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isSearchInHeader: true };
        case buildActionType(SCROLL_NO_HEADER_SEARCH_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isSearchInHeader: false };
        case buildActionType(SCROLL_HEADER_TRANSPARENT_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isHeaderTransparent: true };
        case buildActionType(SCROLL_NO_HEADER_TRANSPARENT_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isHeaderTransparent: false };
        default:
            return state;
    }
};

export { scroller };
