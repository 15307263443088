import { createGenericContext } from './generic-context';

export interface CurrentLocationState {
    coords: {
        longitude: number;
        latitude: number;
    };
}

const GenericContext = createGenericContext<CurrentLocationState>({
    coords: null,
});

export const CurrentLocationProvider = GenericContext.Provider;

export const useCurrentLocationContext = GenericContext.useGenericContext;
